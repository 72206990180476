.Btn {
  background-color: transparent;
  border: 0;

  cursor: pointer;
}

.BtnReset {
  background-color: transparent;
  border: 0;

  cursor: pointer;
}
