.Component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  padding: 205px 0 0 0;
  background-color: #00110e;

  overflow: hidden;
  padding-bottom: 40px;

  @media (max-width: 767.98px) {
    padding: 118px 0 0 0;
    gap: 30px;
  }
  .WrapContent {
    width: 100%;
    height: 87px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;

    .TitleContent {
      width: fit-content;
      font-family: 'Audiowide';
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 40.8px;

      letter-spacing: 0.1em;
      text-transform: uppercase;

      background: linear-gradient(
        93.01deg,
        #4895ff 0%,
        #00ffce 31.77%,
        #847be7 67.19%,
        #d629f7 89.06%,
        #ff00ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;

      @media (max-width: 1160px) {
        text-align: center;
      }

      @media (max-width: 575.98px) {
        font-size: 20px;
        line-height: 25.5px;
      }
    }
  }

  .WrapperDesktop {
    width: calc(100% - 76px);
    max-width: 1015px;
    margin: 0 auto;
    position: relative;
    display: block;
    @media (max-width: 768px) {
      padding-left: 30px;
      padding-right: 30px;
      display: none;
    }

    .Back {
      position: absolute;
      z-index: 2;
      right: -70px;
      top: 62%;
      transform: translateY(-50%);
      cursor: pointer;
    }

    .Next {
      position: absolute;
      left: -70px;
      z-index: 2;
      top: 62%;
      transform: translateY(-50%);
      cursor: pointer;
    }

    .Card {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      &Wrapper {
        display: flex;
        flex-direction: column;
        width: 320px;
        height: 395px;
        padding: 0px 0px 16px 0px;
        gap: 8px;
        background: transparent;

        border-radius: 8px;

        position: relative;
        background-clip: padding-box;
        background-color: #00110e;
        border: 2px solid transparent;

        &:before {
          content: '';
          position: absolute;
          inset: 0;
          z-index: -1;
          margin: -2px;
          background-image: linear-gradient(
            93.01deg,
            #4895ff 0%,
            #00ffce 31.77%,
            #847be7 67.19%,
            #d629f7 89.06%,
            #ff00ff 100%
          );
          border-radius: inherit;
        }

        .Bg {
          width: 260px;
          height: 230px;
          position: absolute;
          bottom: 0;
          left: 0;
        }

        .Item {
          width: 320px;
          height: 150px;
          object-fit: cover;
          border-radius: 4px;
        }

        .CardContent {
          display: flex;
          flex-direction: column;
          gap: 8px;
          z-index: 2;
          padding: 8px 16px 8px 16px;

          .Title {
            min-height: 50px;
            font-family: 'Audiowide';
            font-style: normal;
            font-weight: 400;
            font-size: 19px;
            line-height: 24.22px;
            display: flex;
            align-items: flex-end;
            text-align: left;

            color: #3bffd9;
          }

          .Desc {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;

            text-align: left;

            color: #ffffff;

            opacity: 1;
          }

          .Link {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 5px 12px;
            width: 97px;
            height: 24px;

            background: #00110e;
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2),
              inset 0px 2px 3px rgba(0, 255, 206, 0.06);
            border-radius: 4px;

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 120%;
            /* or 14px */

            text-align: center;
            letter-spacing: 0.085em;
            text-transform: uppercase;

            color: #00ffce;

            opacity: 0.9;
          }
        }
      }
    }
  }

  .WrapperMobile {
    // width: calc(100% - 76px);
    max-width: 320px;
    margin: 0 auto;
    position: relative;
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
    .Contain {
      height: 442.69px;
    }
    .Card {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      &Wrapper {
        display: flex;
        flex-direction: column;
        width: 316px;
        height: 411px;
        gap: 10px;
        background: transparent;

        border-radius: 8px;

        position: relative;
        background-clip: padding-box;
        background-color: #00110e;
        border: 2px solid transparent;

        &:before {
          content: '';
          position: absolute;
          inset: 0;
          z-index: -1;
          margin: -2px;
          background-image: linear-gradient(
            93.01deg,
            #4895ff 0%,
            #00ffce 31.77%,
            #847be7 67.19%,
            #d629f7 89.06%,
            #ff00ff 100%
          );
          border-radius: inherit;
        }

        .Bg {
          width: 260px;
          height: 230px;
          position: absolute;
          bottom: 0;
          left: 0;
        }

        .Item {
          width: 316px;
          height: 150px;
          object-fit: cover;
          border-radius: 4px;
        }

        .CardContent {
          display: flex;
          flex-direction: column;
          gap: 8px;
          z-index: 2;
          padding: 8px 16px 8px 16px;

          .Title {
            min-height: 50px;
            font-family: 'Audiowide';
            font-style: normal;
            font-weight: 400;
            font-size: 19px;
            line-height: 24.22px;
            display: flex;
            align-items: flex-end;
            text-align: left;

            color: #3bffd9;
          }

          .Desc {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 25.5px;

            text-align: left;

            color: #ffffff;

            opacity: 1;
          }

          .Link {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 5px 12px;
            width: 97px;
            height: 24px;

            background: #00110e;
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2),
              inset 0px 2px 3px rgba(0, 255, 206, 0.06);
            border-radius: 4px;

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 120%;
            /* or 14px */

            text-align: center;
            letter-spacing: 0.085em;
            text-transform: uppercase;

            color: #00ffce;

            opacity: 0.9;
          }
        }
      }
    }
  }
}
