.Component {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #00110e;
  overflow: hidden;
  padding: 49px 0 180px 0;
  position: relative;
  @media (max-width: 767.98px) {
    padding: 0 0 40px 0;
    justify-content: center;
  }
  .BG {
    bottom: -100px;
    position: absolute;
    right: 3%;
    rotate: 180deg;
    @media (max-width: 767.98px) {
      width: 100%;
      height: 202.6px;
      bottom: -50px;
    }
  }
  .Wrapper {
    z-index: 2;
    .Box {
      width: 1231px;
      height: 748.11px;

      border-radius: 13.41px;
      border: 0.95px solid;
      position: relative;
      border-image-source: linear-gradient(
        254.98deg,
        #003431 -1.92%,
        #002918 102.95%
      );
      box-shadow: 0px 3.8px 3.8px 0px #00000040;

      box-shadow: 4.75px 4.75px 5.7px 0px #88fff31a inset;

      box-shadow: 0px -1.9px 2.85px 0px #5f8dfc2b inset;

      box-shadow: 0px 4px 4px 0px #49d09740;

      background: linear-gradient(
          105.26deg,
          rgba(0, 182, 222, 0.2) -1.45%,
          rgba(0, 92, 113, 0.2) 24.31%,
          rgba(0, 0, 0, 0.2) 88.46%
        ),
        linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
      @media (max-width: 767.98px) {
        width: 307.775px;
        height: 187.03px;
      }

      .vector1 {
        z-index: 2;
        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: 13.41px;

        @media (max-width: 767.98px) {
          width: 53.49px;
          height: 20.71px;
        }
      }
      .vector2 {
        z-index: 2;
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 13.41px;

        @media (max-width: 767.98px) {
          width: 172.31px;
          height: 164.45px;
        }
      }
      .vector3 {
        z-index: 2;
        position: absolute;

        top: 0;
        right: 50%;
        @media (max-width: 767.98px) {
          width: 53.49px;
          height: 20.71px;
        }
      }
      .video {
        z-index: 8;
        position: absolute;
        top: 30.42px;
        left: 30.42px;
        width: 95%;
        height: 92%;
        object-fit: cover;
        @media (max-width: 767.98px) {
          width: 95%;
          height: 171.82px;
          top: 7.6px;
          left: 7.6px;
        }
      }
    }
  }
}
