* {
  margin: 0;
  list-style: none;
  text-decoration: none;

  color: #fff;
}

html {
  scroll-behavior: smooth;
}

@font-face {
  font-weight: 400;
  font-family: 'Audiowide';
  src: local('Audiowide'),
    url('../assets/fonts/Audiowide-Regular.ttf') format('truetype');
}

@font-face {
  font-weight: 300;
  font-family: 'Poppins';
  src: local('Poppins'),
    url('../assets/fonts/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-weight: 400;
  font-family: 'Poppins';
  src: local('Poppins'),
    url('../assets/fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-weight: 500;
  font-family: 'Poppins';
  src: local('Poppins'),
    url('../assets/fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-weight: 600;
  font-family: 'Poppins';
  src: local('Poppins'),
    url('../assets/fonts/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-weight: 600;
  font-family: 'Poppins';
  src: local('Poppins'),
    url('../assets/fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-weight: 800;
  font-family: 'Poppins';
  src: local('Poppins'),
    url('../assets/fonts/Poppins-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-weight: 700;
  font-family: 'Aileron';
  src: local('Aileron'),
    url('../assets/fonts/Aileron-Bold.otf') format('truetype');
}
.swiper-pagination-bullet {
  background: #ffffff !important;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background: #00ffce !important;
}

.swiper-pagination {
  position: absolute;
  bottom: 0px !important;
}
