.Component {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #00110e;
  overflow: hidden;
  padding: 40px 0 40px 0;
  gap: 32px;
  position: relative;
  width: 100%;
  @media (max-width: 900px) {
    gap: 30;
  }

  .Content {
    z-index: 4;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    .Title {
      text-align: center;
      font-size: 32px;
      font-family: Audiowide;
      font-style: normal;
      font-weight: 400;
      line-height: 40.08px;
      text-transform: uppercase;

      background-clip: text;
      text-fill-color: transparent;
      background: linear-gradient(
        93.01deg,
        #4895ff 0%,
        #00ffce 31.77%,
        #847be7 67.19%,
        #d629f7 89.06%,
        #ff00ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media (max-width: 900px) {
        font-size: 20px;
        line-height: 25.5px;
        letter-spacing: 10%;
      }
    }
    .Text {
      font-size: 32px;
      line-height: 48px;
      color: white;
      font-family: 'Poppins';
      @media (max-width: 900px) {
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 10%;
      }
    }
  }

  .WrapperDesktop {
    width: 100%;
    height: 520px;
    position: relative;
    display: block;
    margin: 0 auto;
    @media (max-width: 767.98px) {
      display: none;
    }
    @media (min-width: 1600px) {
      width: 91.34%;
    }
    .Back {
      position: absolute;
      z-index: 2;
      right: 25%;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;

      @media (max-width: 1260px) {
        right: -44px;
      }
    }

    .Next {
      position: absolute;
      left: 25%;
      z-index: 2;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;

      @media (max-width: 1260px) {
        left: -44px;
      }
    }

    .Contain {
      width: 495.24px;
      height: 520px;
      border-radius: 16px;
      padding: 1px;

      background-image: linear-gradient(
        93.01deg,
        #4895ff 0%,
        #00ffce 31.77%,
        #847be7 67.19%,
        #d629f7 89.06%,
        #ff00ff 100%
      );
      .WrapperCard {
        width: 100%;
        height: 100%;
        border-radius: inherit;
        background: #00110e;
        background-image: linear-gradient(#00110e, rgba(0, 17, 14, 0.5)),
          url(../../../assets/images/testimonial/bg.png);
        background-position: bottom;
        background-size: cover;
        background-repeat: no-repeat;
        background-size: 100% 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        .Card {
          position: relative;
          z-index: 0;
          width: 391.24px;
          height: 412.78px;
          padding: 19.81px;
          gap: 12.38px;
          border-radius: 8px;
          background: linear-gradient(
            119.25deg,
            rgba(40, 45, 44, 0.15) 32.05%,
            rgba(0, 17, 14, 0.105) 102%
          );
          border: 1.24px solid #053944;

          box-shadow: 0px 4.74px 13.54px 0px #00000033;

          box-shadow: 2.03px 2.03px 8.46px 0px #88fff312 inset;

          box-shadow: 1.35px 1.35px 0.68px 0px #ffffff52 inset;

          box-shadow: -1.35px -1.35px 4.06px 0px #ff00ff14 inset;
          background-image: url(../../../assets/images/testimonial/Group45.png);

          .image {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
          }
          .WrapperContent {
            z-index: 4;
            .Content {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;

              gap: 49.52px;

              .Header {
                display: flex;
                align-items: center;
                flex-direction: row;
                gap: 76.76px;
                .WrapperText2 {
                  display: flex;
                  align-items: end;
                  justify-content: end;
                  flex-direction: column;
                  gap: 3.11px;

                  .Text {
                    font-family: Poppins;
                    font-size: 9px;
                    font-weight: 400;
                    line-height: 10.8px;
                    letter-spacing: 0.19em;
                    text-align: right;
                  }
                }
              }

              .Body {
                display: flex;
                flex-direction: column;
                gap: 39.62px;
                .BoxText {
                  width: 100%;
                  height: 185.71px;
                  .text {
                    font-family: Poppins;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 30px;
                    text-align: center;
                  }
                }

                .BoxInfo {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: center;
                  gap: 19.81px;
                  .Info {
                    display: flex;
                    flex-direction: column;
                    gap: 9.9px;
                    .Name {
                      font-family: Audiowide;
                      font-size: 22px;
                      font-weight: 400;
                      line-height: 28.05px;
                      text-align: left;
                      background: linear-gradient(
                        92.97deg,
                        #4895ff 0%,
                        #00ffce 53.25%,
                        #847be7 112.62%,
                        #d629f7 149.29%,
                        #ff00ff 167.62%
                      );
                      background-clip: text;
                      text-fill-color: transparent;

                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                    .Role {
                      display: flex;
                      flex-direction: column;
                      gap: 0;

                      .title {
                        font-family: Poppins;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 27px;
                        text-align: left;
                      }
                      .spanTitle {
                        font-family: Poppins;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 27px;
                        text-align: left;
                      }
                      .Desc {
                        font-family: Poppins;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 27px;
                        text-align: left;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .WrapperMobile {
    // width: calc(100% - 76px);
    max-width: 311px;
    margin: 0 auto;
    position: relative;
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
    .Cover {
      height: 394.5px;
    }
    .Contain {
      width: 309px;
      height: 324.5px;
      border-radius: 16px;
      padding: 1px;

      background-image: linear-gradient(
        93.01deg,
        #4895ff 0%,
        #00ffce 31.77%,
        #847be7 67.19%,
        #d629f7 89.06%,
        #ff00ff 100%
      );
      .WrapperCard {
        width: 100%;
        height: 100%;
        border-radius: inherit;
        background: #00110e;
        background-image: linear-gradient(#00110e, rgba(0, 17, 14, 0.5)),
          url(../../../assets/images/testimonial/bg.png);
        background-position: bottom;
        background-size: cover;
        background-repeat: no-repeat;
        background-size: 100% 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        .Card {
          position: relative;
          z-index: 0;
          width: 244.11px;
          height: 257.55px;
          padding: 12.36px;
          gap: 7.72px;
          border-radius: 8px;
          background: linear-gradient(
            119.25deg,
            rgba(40, 45, 44, 0.15) 32.05%,
            rgba(0, 17, 14, 0.105) 102%
          );
          border: 1.24px solid #053944;

          box-shadow: 0px 4.74px 13.54px 0px #00000033;

          box-shadow: 2.03px 2.03px 8.46px 0px #88fff312 inset;

          box-shadow: 1.35px 1.35px 0.68px 0px #ffffff52 inset;

          box-shadow: -1.35px -1.35px 4.06px 0px #ff00ff14 inset;
          background-image: url(../../../assets/images/testimonial/Group45.png);

          .image {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
          }
          .WrapperContent {
            z-index: 4;
            position: absolute;
            .Content {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              gap: 30.9px;

              .Header {
                display: flex;
                align-items: center;
                flex-direction: row;
                gap: 47.9px;
                .logo {
                  width: 57.07px;
                  height: 9.36px;
                  object-fit: contain;
                }
                .WrapperText2 {
                  display: flex;
                  align-items: end;
                  justify-content: end;
                  flex-direction: column;
                  gap: 1.94px;

                  .Text {
                    font-family: Poppins;
                    font-size: 6px;
                    font-weight: 400;
                    line-height: 120%;
                    letter-spacing: 19%;
                    text-align: right;
                  }
                  .ai {
                    height: 8px;
                    width: 120px;
                  }
                  .chapter2 {
                    height: 6px;
                    width: 29.86px;
                  }
                }
              }

              .Body {
                display: flex;
                flex-direction: column;
                gap: 24.72px;
                .BoxText {
                  width: 100%;
                  height: 111.87px;
                  .text {
                    font-family: Poppins;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 150%;
                    text-align: center;
                  }
                }

                .BoxInfo {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: center;
                  gap: 12.36px;
                  .imageInfo {
                    width: 56.52px;
                    height: 61.8px;
                    object-fit: contain;
                  }
                  .Info {
                    display: flex;
                    flex-direction: column;
                    gap: 6.18px;
                    .Name {
                      font-family: Audiowide;
                      font-size: 14px;
                      font-weight: 400;
                      text-align: left;
                      background: linear-gradient(
                        92.97deg,
                        #4895ff 0%,
                        #00ffce 53.25%,
                        #847be7 112.62%,
                        #d629f7 149.29%,
                        #ff00ff 167.62%
                      );
                      background-clip: text;
                      text-fill-color: transparent;

                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                    .Role {
                      display: flex;
                      flex-direction: column;
                      gap: 0;

                      .title {
                        font-family: Poppins;
                        font-size: 12px;
                        font-weight: 400;
                        text-align: left;
                        color: white;
                      }
                      .spanTitle {
                        font-family: Poppins;
                        font-size: 12px;
                        font-weight: 700;
                        text-align: left;
                        color: white;
                      }
                      .Desc {
                        font-family: Poppins;
                        font-size: 12px;
                        font-weight: 700;
                        text-align: left;
                        color: white;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
