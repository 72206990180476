.Component {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 163.23px;
  width: 100%;
  // height: 1159px;
  height: 2260px;
  position: relative;
  gap: 32px;
  background-image: url(../../assets/images/bgG.png);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 100% 50%;
  @media (max-width: 767.98px) {
    margin-top: 119px;
    height: 4680px;
    background-size: 100% 10%;
  }
  .Content {
    .Text {
      width: fit-content;
      font-family: 'Audiowide';
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 40.08px;
      text-transform: uppercase;
      text-align: center;

      background: linear-gradient(
        93.01deg,
        #4895ff 0%,
        #00ffce 31.77%,
        #847be7 67.19%,
        #d629f7 89.06%,
        #ff00ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;

      @media (max-width: 700px) {
        font-size: 20px;
        line-height: 25.5px;
      }
    }
  }

  .TabBar {
    z-index: 2;
    display: flex;
    gap: 32px;
    align-items: center;
    justify-content: center;
    @media (max-width: 767.98px) {
      gap: 13.41px;
    }
    .Box {
      width: 226px;
      height: 43px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
      cursor: pointer;
      @media (max-width: 767.98px) {
        height: 11.16px;
        width: fit-content;
        padding: 8px;
        gap: 3.33px;
      }
      .Text {
        font-family: Audiowide;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.2px;
        text-align: center;
        color: white;
        @media (max-width: 767.98px) {
          font-family: Audiowide;
          font-size: 6.38px;
          font-weight: 400;
          line-height: 7.66px;
          text-align: center;
        }
      }
      .Line {
        height: 1px;
        width: 102px;
        background: white;
        @media (max-width: 767.98px) {
          width: 42.76px;
        }
      }
      .active {
        color: #00ffce;
      }
      .Line.active {
        background: #00ffce;
      }
    }
  }
  .Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 32px;
    width: 100%;
  }
  .Contain {
    z-index: 5;
    // display: grid;
    // grid-template-columns: repeat(5, 1fr);
    // grid-template-rows: repeat(5, 1fr);

    // grid-column-gap: 32px;
    // row-gap: 32px;
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    row-gap: 32px;
    column-gap: 32px;
    // width: 1236px;
    // height: 900px;
    width: 1400px;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
    @media (max-width: 767.98px) {
      grid-column-gap: 7px;
      row-gap: 7px;
      width: 310px;
      height: 850px;
      grid-template-columns: repeat(2, 1fr);
      display: grid;
    }

    .ContainBox {
      z-index: 5;
      width: 220.42px;
      height: 233.36px;
      padding: 22.04px 13.22px 22.04px 13.22px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 23.51px;
      border-radius: 7.35px;
      background: linear-gradient(
          105.26deg,
          rgba(0, 182, 222, 0.2) -1.45%,
          rgba(0, 92, 113, 0.2) 24.31%,
          rgba(0, 0, 0, 0.2) 88.46%
        ),
        linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
      box-shadow: 3.67px 3.67px 4.41px 0px #88fff31a inset;

      box-shadow: 0px -1.47px 2.2px 0px #5f8dfc2b inset;

      box-shadow: 0px 2.94px 2.94px 0px #00000040;

      @media (max-width: 767.98px) {
        width: 131.58px;
        height: 232px;
      }
      .Image {
        height: 82.12px;

        @media (max-width: 767.98px) {
          height: 85px;
        }
      }
      .Image6 {
        height: 82.12px;
        width: 82.12px;
        @media (max-width: 767.98px) {
          width: 85px;
          height: 85px;
        }
      }
      .Image7 {
        height: 82.12px;
        width: 82.12px;
        @media (max-width: 767.98px) {
          width: 85px;
          height: 85px;
        }
      }
      .Image1 {
        height: 82.12px;
        width: 82.12px;
        @media (max-width: 767.98px) {
          width: 85px;
          height: 85px;
        }
      }
      .Image8 {
        height: 82.12px;
        width: 82.12px;
        @media (max-width: 767.98px) {
          width: 85px;
          height: 85px;
        }
      }
      .Image9 {
        height: 82.12px;
        width: 82.12px;
        @media (max-width: 767.98px) {
          width: 85px;
          height: 85px;
        }
      }
      .Content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8.82px;
      }
      .Name {
        width: fit-content;
        font-family: Audiowide;
        font-size: 16px;
        font-weight: 400;
        line-height: 20.4px;
        text-align: center;

        text-transform: capitalize;

        background: linear-gradient(
          93.01deg,
          #4895ff 0%,
          #00ffce 31.77%,
          #847be7 67.19%,
          #d629f7 89.06%,
          #ff00ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        @media (max-width: 767.98px) {
          font-family: Audiowide;
          font-size: 12px;
          font-weight: 400;
          line-height: 15.3px;
          text-align: center;
        }
      }
      .Desc {
        color: #fff;
        text-align: center;
        font-size: 11px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 400;
        line-height: 16.5px;
        letter-spacing: 7%;
        text-transform: uppercase;
        margin-bottom: -5px;

        opacity: 0.9;
      }
      .Company {
        color: #fff;
        text-align: center;
        font-size: 11px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 700;
        line-height: 16.5px;
        letter-spacing: 7%;
        text-transform: uppercase;
        margin-bottom: -5px;

        opacity: 0.9;
      }
    }
  }
  .Pagination {
    z-index: 5;
    cursor: pointer;
    position: relative;
    flex-direction: row;
    gap: 16px;

    display: flex;
  }
  .Pagination button {
    z-index: 5;

    cursor: pointer;
    font-family: Audiowide;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;
    width: 35px;
    height: 35px;
    padding: 8px 0px;

    gap: 10px;
    border-radius: 4px;
    background: linear-gradient(
        105.26deg,
        rgba(0, 182, 222, 0.2) -1.45%,
        rgba(0, 92, 113, 0.2) 24.31%,
        rgba(0, 0, 0, 0.2) 88.46%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  }

  .Pagination button.active {
    font-family: Audiowide;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;

    z-index: 5;

    width: 35px;
    height: 35px;
    padding: 8px 0px;
    gap: 10px;
    border-radius: 4px;

    color: #00ffce;
    box-shadow: 1px 1px 2px 0px #3ec1b11a;

    box-shadow: 2px 4px 4px 0px #3ec1b117;

    box-shadow: 5px 8px 6px 0px #3ec1b10d;

    box-shadow: 9px 14px 7px 0px #3ec1b103;

    box-shadow: 14px 22px 7px 0px #3ec1b100;
    background: linear-gradient(
        105.26deg,
        rgba(0, 182, 222, 0.2) -1.45%,
        rgba(0, 92, 113, 0.2) 24.31%,
        rgba(0, 0, 0, 0.2) 88.46%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  }
}
