.Component {
  display: flex;
  flex-direction: column;

  width: 100%;
  background: #00110e;

  overflow: hidden;

  @media (max-width: 1240px) {
    width: calc(100% - 40px);
    padding: 40px 25px 40px 25px;
  }
  .Business {
    display: flex;
    flex-direction: column;

    width: 100%;
    padding: 40px 0px 40px 0px;
    @media (max-width: 767.98px) {
      padding: 0;
      gap: 30px;
    }
    .Content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      justify-content: center;
      width: 100%;
      height: 152px;
      @media (max-width: 700px) {
        height: 60px;
      }
      .Text {
        width: fit-content;
        font-family: 'Audiowide';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 40.08px;
        text-transform: uppercase;
        text-align: center;

        background: linear-gradient(
          93.01deg,
          #4895ff 0%,
          #00ffce 31.77%,
          #847be7 67.19%,
          #d629f7 89.06%,
          #ff00ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        @media (max-width: 700px) {
          font-size: 20px;
          line-height: 25.5px;
        }
      }

      .Title {
        width: fit-content;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 48px;

        color: #ffffff;

        @media (max-width: 1160px) {
          text-align: center;
        }

        @media (max-width: 700px) {
          font-size: 20px;
          line-height: 30px;
          letter-spacing: 10%;
        }
      }
    }

    .WrapperDesktop {
      max-width: 1326px;
      margin: 0 auto;
      position: relative;
      display: block;
      @media (max-width: 767.98px) {
        display: none;
      }
      .Back {
        position: absolute;
        z-index: 2;
        right: -70px;
        top: 62%;
        transform: translateY(-50%);
        cursor: pointer;
      }

      .Next {
        position: absolute;
        left: -70px;
        z-index: 2;
        top: 62%;
        transform: translateY(-50%);
        cursor: pointer;
      }

      .Card {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        &Wrapper {
          display: flex;
          flex-direction: column;
          width: 355.33px;
          height: 455px;
          gap: 16px;
          background: linear-gradient(
            180deg,
            #00041f 60%,
            rgba(17, 17, 17, 0.7) 93%
          );
          padding: 16px;
          border-radius: 24px;
          // box-shadow: 1px
          //   linear-gradient(
          //     105.64deg,
          //     rgba(0, 4, 31, 0.6) 0%,
          //     rgba(17, 17, 17, 0.7) 93%
          //   );
          // backdrop-filter: blur(1px);
          /* Note: backdrop-filter has minimal browser support */
          border: 1px solid #084122;
          border-radius: 10px;

          position: relative;
          .Bg {
            width: 100%;
            height: 79.59px;
            position: absolute;
            top: 0;
            left: 0;
          }

          .Item {
            border-radius: 8px;

            width: 355.33px;
            max-height: 203px;

            object-fit: contain;
          }

          .LastItem {
            max-width: 189px;
          }

          .CardContent {
            display: flex;
            flex-direction: column;
            gap: 8px;
            position: relative;
            .Title {
              min-height: 33px;
              font-family: 'Audiowide';
              font-style: normal;
              font-weight: 400;
              font-size: 25px;
              line-height: 33.15px;
              text-align: center;

              /* One/Gradient */

              background: linear-gradient(
                93.01deg,
                #4895ff 0%,
                #00ffce 31.77%,
                #847be7 67.19%,
                #d629f7 89.06%,
                #ff00ff 100%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;

              @media (max-width: 700px) {
                font-size: 18px;
                line-height: 22.95px;
              }
            }

            .Link {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              padding: 5px 12px;
              width: 97px;
              height: 24px;

              background: #00110e;
              box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2),
                inset 0px 2px 3px rgba(0, 255, 206, 0.06);
              border-radius: 4px;

              font-family: 'Poppins';
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 120%;
              /* or 14px */

              text-align: center;
              letter-spacing: 0.085em;
              text-transform: uppercase;

              color: #00ffce;

              opacity: 0.9;
            }

            .Text {
              font-family: Poppins;
              font-size: 18px;
              font-weight: 400;
              line-height: 27px;
              text-align: center;
              color: white;
            }
          }
        }
      }
    }

    .WrapperMobile {
      width: 303.5px;
      margin: 0 auto;
      position: relative;
      display: none;
      @media (max-width: 767.98px) {
        display: block;
      }
      .Back {
        position: absolute;
        z-index: 2;
        right: -70px;
        top: 62%;
        transform: translateY(-50%);
        cursor: pointer;
      }

      .Next {
        position: absolute;
        left: -70px;
        z-index: 2;
        top: 62%;
        transform: translateY(-50%);
        cursor: pointer;
      }
      .Contain {
        height: 440px + 15.5px;
      }
      .Card {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        &Wrapper {
          display: flex;
          flex-direction: column;
          width: 270px;
          height: 389px;
          gap: 16px;
          background: linear-gradient(
            180deg,
            #00041f 60%,
            rgba(17, 17, 17, 0.7) 93%
          );
          padding: 16px;
          border-radius: 24px;
          // box-shadow: 1px
          //   linear-gradient(
          //     105.64deg,
          //     rgba(0, 4, 31, 0.6) 0%,
          //     rgba(17, 17, 17, 0.7) 93%
          //   );
          // backdrop-filter: blur(1px);
          /* Note: backdrop-filter has minimal browser support */
          border: 1px solid #084122;
          border-radius: 10px;

          position: relative;
          .Bg {
            width: 100%;
            height: 79.59px;
            position: absolute;
            top: 0;
            left: 0;
          }

          .Item {
            border-radius: 8px;

            width: 278px;
            height: 150px;

            object-fit: contain;
          }

          .CardContent {
            display: flex;
            flex-direction: column;
            gap: 8px;
            .Title {
              min-height: 23px;
              font-family: 'Audiowide';
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 22.95px;
              text-align: center;

              /* One/Gradient */

              background: linear-gradient(
                93.01deg,
                #4895ff 0%,
                #00ffce 31.77%,
                #847be7 67.19%,
                #d629f7 89.06%,
                #ff00ff 100%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;

              @media (max-width: 700px) {
                font-size: 18px;
                line-height: 22.95px;
              }
            }

            .Link {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              padding: 5px 12px;
              width: 97px;
              height: 24px;

              background: #00110e;
              box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2),
                inset 0px 2px 3px rgba(0, 255, 206, 0.06);
              border-radius: 4px;

              font-family: 'Poppins';
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 120%;
              /* or 14px */

              text-align: center;
              letter-spacing: 0.085em;
              text-transform: uppercase;

              color: #00ffce;

              opacity: 0.9;
            }
            .Text {
              font-family: Poppins;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              text-align: center;
            }
          }
        }
      }
    }
  }

  .Workshop {
    display: flex;
    flex-direction: column;

    width: 100%;
    padding: 40px 0px 40px 0px;

    .Content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      justify-content: center;
      width: 100%;
      height: 152px;
      @media (max-width: 700px) {
        height: 60px;
      }
      .Text {
        width: fit-content;
        font-family: 'Audiowide';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 40.08px;

        text-align: center;

        background: linear-gradient(
          93.01deg,
          #4895ff 0%,
          #00ffce 31.77%,
          #847be7 67.19%,
          #d629f7 89.06%,
          #ff00ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        @media (max-width: 700px) {
          font-size: 20px;
          line-height: 25.5px;
        }
      }

      .Title {
        width: fit-content;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 48px;
        text-transform: capitalize;
        color: #ffffff;

        @media (max-width: 1160px) {
          text-align: center;
        }

        @media (max-width: 700px) {
          font-size: 20px;
          line-height: 30px;
          letter-spacing: 10%;
        }
      }
    }

    .WrapperDesktop {
      max-width: 942px;
      margin: 0 auto;
      position: relative;
      display: block;
      @media (max-width: 767.98px) {
        display: none;
      }
      .Back {
        position: absolute;
        z-index: 2;
        right: -70px;
        top: 62%;
        transform: translateY(-50%);
        cursor: pointer;
      }

      .Next {
        position: absolute;
        left: -70px;
        z-index: 2;
        top: 62%;
        transform: translateY(-50%);
        cursor: pointer;
      }

      .Card {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        min-height: 249px;

        &Wrapper {
          display: flex;
          flex-direction: column;
          width: 293px;
          height: 181px;

          background: linear-gradient(
            119.25deg,
            rgba(40, 45, 44, 0.15) 32.05%,
            rgba(0, 17, 14, 0.105) 102%
          );
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.14);
          backdrop-filter: blur(7px);
          /* Note: backdrop-filter has minimal browser support */

          border-radius: 10px;

          position: relative;

          .Bg {
            width: 100%;
            height: 79.59px;
            position: absolute;
            top: 0;
            left: 0;
          }

          .Item {
            position: absolute;
            top: -40px;
            left: 50%;

            max-width: 138px;
            width: auto;
            max-height: 138px;
            height: auto;

            object-fit: contain;

            transform: translateX(-50%);
          }

          .LastItem {
            max-width: 189px;
          }

          .CardContent {
            height: 46px;
            position: absolute;
            top: 105px;
            .Title {
              min-height: 50px;
              font-family: 'Audiowide';
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 22.95px;
              display: flex;
              align-items: flex-end;
              text-align: center;

              /* One/Gradient */

              background: linear-gradient(
                93.01deg,
                #4895ff 0%,
                #00ffce 31.77%,
                #847be7 67.19%,
                #d629f7 89.06%,
                #ff00ff 100%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;

              @media (max-width: 700px) {
                font-size: 18px;
                line-height: 22.95px;
              }
            }

            .Link {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              padding: 5px 12px;
              width: 97px;
              height: 24px;

              background: #00110e;
              box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2),
                inset 0px 2px 3px rgba(0, 255, 206, 0.06);
              border-radius: 4px;

              font-family: 'Poppins';
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 120%;
              /* or 14px */

              text-align: center;
              letter-spacing: 0.085em;
              text-transform: uppercase;

              color: #00ffce;

              opacity: 0.9;
            }
          }
        }
      }
    }

    .WrapperMobile {
      max-width: 290px;
      margin: 0 auto;
      position: relative;
      display: none;
      @media (max-width: 767.98px) {
        display: block;
      }
      .Contain {
        height: 281.5px;
      }
      .Card {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        min-height: 249px;

        &Wrapper {
          display: flex;
          flex-direction: column;
          width: 293px;
          height: 181px;

          background: linear-gradient(
            119.25deg,
            rgba(40, 45, 44, 0.15) 32.05%,
            rgba(0, 17, 14, 0.105) 102%
          );
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.14);
          backdrop-filter: blur(7px);
          /* Note: backdrop-filter has minimal browser support */

          border-radius: 10px;

          position: relative;

          .Bg {
            width: 100%;
            height: 79.59px;
            position: absolute;
            top: 0;
            left: 0;
          }

          .Item {
            position: absolute;
            top: -40px;
            left: 50%;

            max-width: 138px;
            width: auto;
            max-height: 138px;
            height: auto;

            object-fit: contain;

            transform: translateX(-50%);
          }

          .LastItem {
            max-width: 189px;
          }

          .CardContent {
            height: 46px;
            position: absolute;
            top: 105px;
            .Title {
              min-height: 50px;
              font-family: 'Audiowide';
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 22.95px;
              display: flex;
              align-items: flex-end;
              text-align: center;

              /* One/Gradient */

              background: linear-gradient(
                93.01deg,
                #4895ff 0%,
                #00ffce 31.77%,
                #847be7 67.19%,
                #d629f7 89.06%,
                #ff00ff 100%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;

              @media (max-width: 700px) {
                font-size: 18px;
                line-height: 22.95px;
              }
            }

            .Link {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              padding: 5px 12px;
              width: 97px;
              height: 24px;

              background: #00110e;
              box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2),
                inset 0px 2px 3px rgba(0, 255, 206, 0.06);
              border-radius: 4px;

              font-family: 'Poppins';
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 120%;
              /* or 14px */

              text-align: center;
              letter-spacing: 0.085em;
              text-transform: uppercase;

              color: #00ffce;

              opacity: 0.9;
            }
          }
        }
      }
    }
  }

  .Panel {
    display: flex;
    flex-direction: column;

    width: 100%;
    padding: 40px 0px 40px 0px;

    .Content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      justify-content: center;
      width: 100%;
      height: 152px;
      @media (max-width: 700px) {
        height: 60px;
      }
      .Text {
        width: fit-content;
        font-family: 'Audiowide';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 40.08px;
        text-transform: uppercase;
        text-align: center;

        background: linear-gradient(
          93.01deg,
          #4895ff 0%,
          #00ffce 31.77%,
          #847be7 67.19%,
          #d629f7 89.06%,
          #ff00ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        @media (max-width: 700px) {
          font-size: 20px;
          line-height: 25.5px;
        }
      }

      .Title {
        width: fit-content;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 48px;

        color: #ffffff;

        @media (max-width: 1160px) {
          text-align: center;
        }

        @media (max-width: 700px) {
          font-size: 20px;
          line-height: 30px;
          letter-spacing: 10%;
        }
      }
    }

    .WrapperDesktop {
      max-width: 830px;
      margin: 0 auto;
      position: relative;
      display: block;
      @media (max-width: 767.98px) {
        display: none;
      }
      .Back {
        position: absolute;
        z-index: 2;
        right: -70px;
        top: 62%;
        transform: translateY(-50%);
        cursor: pointer;
      }

      .Next {
        position: absolute;
        left: -70px;
        z-index: 2;
        top: 62%;
        transform: translateY(-50%);
        cursor: pointer;
      }

      .Card {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        min-height: 331px;

        &Wrapper {
          display: flex;
          flex-direction: column;
          width: 224px;
          height: 115px;
          padding: 85px 18px 30px 18px;

          background: linear-gradient(
            119.25deg,
            rgba(40, 45, 44, 0.15) 32.05%,
            rgba(0, 17, 14, 0.105) 102%
          );
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.14);
          backdrop-filter: blur(7px);
          /* Note: backdrop-filter has minimal browser support */

          border-radius: 10px;

          position: relative;

          .Bg {
            width: 260px;
            height: 230px;
            position: absolute;
            top: 0;
            right: 0;
          }

          .Item {
            position: absolute;
            top: -73px;
            left: 50%;

            max-width: 138px;
            width: auto;
            max-height: 138px;
            height: auto;

            object-fit: contain;

            transform: translateX(-50%);
          }

          .LastItem {
            max-width: 189px;
          }

          .CardContent {
            height: 100px;

            .Title {
              min-height: 50px;
              font-family: 'Audiowide';
              font-style: normal;
              font-weight: 400;
              font-size: 20px;
              line-height: 25.5px;
              display: flex;
              align-items: flex-end;
              text-align: center;

              /* One/Gradient */

              background: linear-gradient(
                93.01deg,
                #4895ff 0%,
                #00ffce 31.77%,
                #847be7 67.19%,
                #d629f7 89.06%,
                #ff00ff 100%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;

              @media (max-width: 700px) {
                font-size: 18px;
                line-height: 22.95px;
              }
            }

            .Link {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              padding: 5px 12px;
              width: 97px;
              height: 24px;

              background: #00110e;
              box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2),
                inset 0px 2px 3px rgba(0, 255, 206, 0.06);
              border-radius: 4px;

              font-family: 'Poppins';
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 120%;
              /* or 14px */

              text-align: center;
              letter-spacing: 0.085em;
              text-transform: uppercase;

              color: #00ffce;

              opacity: 0.9;
            }
          }
        }
      }
    }

    .WrapperMobile {
      max-width: 260px;
      margin: 0 auto;
      position: relative;

      display: none;
      @media (max-width: 767.98px) {
        display: block;
      }
      .Contain {
        height: 364.5px;
      }
      .Card {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        min-height: 331px;

        &Wrapper {
          display: flex;
          flex-direction: column;
          width: 224px;
          height: 115px;
          padding: 85px 18px 30px 18px;

          background: linear-gradient(
            119.25deg,
            rgba(40, 45, 44, 0.15) 32.05%,
            rgba(0, 17, 14, 0.105) 102%
          );
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.14);
          backdrop-filter: blur(7px);
          /* Note: backdrop-filter has minimal browser support */

          border-radius: 10px;

          position: relative;

          .Bg {
            width: 260px;
            height: 230px;
            position: absolute;
            top: 0;
            right: 0;
          }

          .Item {
            position: absolute;
            top: -73px;
            left: 50%;

            max-width: 138px;
            width: auto;
            max-height: 138px;
            height: auto;

            object-fit: contain;

            transform: translateX(-50%);
          }

          .LastItem {
            max-width: 189px;
          }

          .CardContent {
            height: 100px;

            .Title {
              min-height: 50px;
              font-family: 'Audiowide';
              font-style: normal;
              font-weight: 400;
              font-size: 20px;
              line-height: 25.5px;
              display: flex;
              align-items: flex-end;
              text-align: center;

              /* One/Gradient */

              background: linear-gradient(
                93.01deg,
                #4895ff 0%,
                #00ffce 31.77%,
                #847be7 67.19%,
                #d629f7 89.06%,
                #ff00ff 100%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;

              @media (max-width: 700px) {
                font-size: 18px;
                line-height: 22.95px;
              }
            }

            .Link {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              padding: 5px 12px;
              width: 97px;
              height: 24px;

              background: #00110e;
              box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2),
                inset 0px 2px 3px rgba(0, 255, 206, 0.06);
              border-radius: 4px;

              font-family: 'Poppins';
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 120%;
              /* or 14px */

              text-align: center;
              letter-spacing: 0.085em;
              text-transform: uppercase;

              color: #00ffce;

              opacity: 0.9;
            }
          }
        }
      }
    }
  }

  .Fireside {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    padding: 40px 0px 40px 0px;
    @media (max-width: 767.98px) {
      gap: 30px;
      padding: 0px;
    }
    .Content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      justify-content: center;
      width: 100%;
      height: 48px;
      @media (max-width: 700px) {
        height: 60px;
      }
      .Text {
        width: fit-content;
        font-family: 'Audiowide';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 40.08px;
        text-transform: uppercase;

        text-align: center;

        background: linear-gradient(
          93.01deg,
          #4895ff 0%,
          #00ffce 31.77%,
          #847be7 67.19%,
          #d629f7 89.06%,
          #ff00ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        @media (max-width: 700px) {
          font-size: 20px;
          line-height: 25.5px;
        }
      }

      .Title {
        width: fit-content;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 48px;

        color: #ffffff;

        @media (max-width: 1160px) {
          text-align: center;
        }

        @media (max-width: 700px) {
          font-size: 20px;
          line-height: 30px;
          letter-spacing: 10%;
        }
      }
    }

    .WrapperDesktop {
      max-width: 1000px;
      margin: 0 auto;
      position: relative;
      display: block;
      @media (max-width: 767.98px) {
        display: none;
      }
      .Back {
        position: absolute;
        z-index: 2;
        right: -70px;
        top: 62%;
        transform: translateY(-50%);
        cursor: pointer;
      }

      .Next {
        position: absolute;
        left: -70px;
        z-index: 2;
        top: 62%;
        transform: translateY(-50%);
        cursor: pointer;
      }
      .Card {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        min-height: 300px;

        &Wrapper {
          display: flex;
          flex-direction: column;
          width: 463px;
          height: 138px;
          padding: 30px 10px 42px 10px;
          gap: 10px;
          background: linear-gradient(
            105.64deg,
            rgba(0, 4, 31, 0.6) 0%,
            rgba(17, 17, 17, 0.7) 93%
          );

          box-shadow: 9px 9px 28px 0px #114f391a;
          backdrop-filter: blur(15px);

          /* Note: backdrop-filter has minimal browser support */

          border-radius: 8px;

          position: relative;
          .Bg {
            width: 100%;
            height: 79.59px;
            position: absolute;
            top: 0;
            left: 0;
          }
          .glow2 {
            position: absolute;
            top: 0px;
            right: 0px;
            border-radius: 8px;
          }

          .Item {
            position: absolute;
            top: -83px;
            left: 50%;

            max-width: 138px;
            width: auto;
            max-height: 138px;
            height: auto;

            object-fit: contain;

            transform: translateX(-50%);
          }

          .LastItem {
            position: absolute;
            top: -70px;
            left: 50%;
            width: 193.19px;
            height: 150px;
          }

          .CardContent {
            display: flex;
            flex-direction: column;
            gap: 8px;
            position: absolute;
            bottom: 42px;
            justify-content: center;
            align-items: center;
            width: 100%;
            .Title {
              min-height: 25px;
              font-family: 'Audiowide';
              font-style: normal;
              font-weight: 400;
              font-size: 25px;
              line-height: 33.15px;
              text-align: center;

              /* One/Gradient */

              background: linear-gradient(
                93.01deg,
                #4895ff 0%,
                #00ffce 31.77%,
                #847be7 67.19%,
                #d629f7 89.06%,
                #ff00ff 100%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;

              @media (max-width: 700px) {
                font-size: 18px;
                line-height: 22.95px;
              }
            }
            .Desc {
              min-height: 50px;
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 300;
              font-size: 18px;
              line-height: 23.4px;
              text-align: center;
              color: #ffffff;
              @media (max-width: 700px) {
                font-size: 15px;
                line-height: 19.5px;
              }
            }
            .Link {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              padding: 5px 12px;
              width: 97px;
              height: 24px;

              background: #00110e;
              box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2),
                inset 0px 2px 3px rgba(0, 255, 206, 0.06);
              border-radius: 4px;

              font-family: 'Poppins';
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 120%;
              /* or 14px */

              text-align: center;
              letter-spacing: 0.085em;
              text-transform: uppercase;

              color: #00ffce;

              opacity: 0.9;
            }
            .glow1 {
              position: absolute;
              bottom: -42px;
              left: 0;
            }
            .glow2 {
              position: absolute;
              top: -132px;
              right: 10px;
            }
          }
        }
      }
    }

    .WrapperMobile {
      max-width: 310px;
      margin: 0 auto;
      position: relative;
      display: none;
      @media (max-width: 767.98px) {
        display: block;
      }
      .Card {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        min-height: 252px;

        &Wrapper {
          display: flex;
          flex-direction: column;
          width: 293px;
          height: 252px;
          padding: 30px 10px 42px 10px;
          gap: 10px;

          position: relative;
          .Bg {
            width: 100%;
            height: 79.59px;
            position: absolute;
            top: 0;
            left: 0;
          }

          .Item {
            position: absolute;
            top: 0px;
            left: 50%;
            z-index: 2;
            max-width: 140px;
            width: auto;
            max-height: 130px;
            height: auto;

            object-fit: contain;

            transform: translateX(-50%);
          }

          .LastItem {
            max-width: 189px;
          }

          .CardContent {
            display: flex;
            flex-direction: column;
            gap: 8px;
            position: absolute;
            bottom: 42px;
            justify-content: center;
            align-items: center;
            width: 273px;
            height: 187px;
            background: linear-gradient(
              105.64deg,
              rgba(0, 4, 31, 0.6) 0%,
              rgba(17, 17, 17, 0.7) 93%
            );

            box-shadow: 9px 9px 28px 0px #114f391a;
            backdrop-filter: blur(15px);

            /* Note: backdrop-filter has minimal browser support */

            border-radius: 8px;
            .Title {
              font-family: 'Audiowide';
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 22.95px;
              text-align: center;

              /* One/Gradient */

              background: linear-gradient(
                93.01deg,
                #4895ff 0%,
                #00ffce 31.77%,
                #847be7 67.19%,
                #d629f7 89.06%,
                #ff00ff 100%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;

              @media (max-width: 700px) {
                font-size: 18px;
                line-height: 22.95px;
              }
            }
            .Desc {
              min-height: 50px;
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 300;
              font-size: 15px;
              line-height: 19.5px;
              text-align: center;
              color: #ffffff;
              @media (max-width: 700px) {
                font-size: 15px;
                line-height: 19.5px;
              }
            }
            .Link {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              padding: 5px 12px;
              width: 97px;
              height: 24px;

              background: #00110e;
              box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2),
                inset 0px 2px 3px rgba(0, 255, 206, 0.06);
              border-radius: 4px;

              font-family: 'Poppins';
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 120%;
              /* or 14px */

              text-align: center;
              letter-spacing: 0.085em;
              text-transform: uppercase;

              color: #00ffce;

              opacity: 0.9;
            }
            .glow1 {
              position: absolute;
              bottom: 0px;
              left: 0;
            }
            .glow2 {
              position: absolute;
              top: 0px;
              right: 0px;
              width: 100%;
            }
          }
        }
      }
    }
  }

  .Presentation {
    display: flex;
    flex-direction: column;

    width: 100%;
    padding: 40px 0px 40px 0px;
    gap: 32px;
    .Content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      justify-content: center;
      width: 100%;
      height: 48px;
      @media (max-width: 700px) {
        height: 60px;
      }
      .Text {
        width: fit-content;
        font-family: 'Audiowide';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 40.08px;
        text-transform: uppercase;
        text-align: center;

        background: linear-gradient(
          93.01deg,
          #4895ff 0%,
          #00ffce 31.77%,
          #847be7 67.19%,
          #d629f7 89.06%,
          #ff00ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        @media (max-width: 700px) {
          font-size: 20px;
          line-height: 25.5px;
        }
      }

      .Title {
        width: fit-content;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 48px;

        color: #ffffff;

        @media (max-width: 1160px) {
          text-align: center;
        }

        @media (max-width: 700px) {
          font-size: 20px;
          line-height: 30px;
          letter-spacing: 10%;
        }
      }
    }

    .WrapperDesktop {
      max-width: 1000px;
      margin: 0 auto;
      position: relative;
      display: block;
      @media (max-width: 767.98px) {
        display: none;
      }
      .Back {
        position: absolute;
        z-index: 2;
        right: -70px;
        top: 62%;
        transform: translateY(-50%);
        cursor: pointer;
      }

      .Next {
        position: absolute;
        left: -70px;
        z-index: 2;
        top: 62%;
        transform: translateY(-50%);
        cursor: pointer;
      }

      .Card {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        min-height: 300px;

        &Wrapper {
          display: flex;
          flex-direction: column;
          width: 463px;
          height: 138px;
          padding: 30px 10px 42px 10px;
          gap: 10px;
          background: linear-gradient(
            105.64deg,
            rgba(0, 4, 31, 0.6) 0%,
            rgba(17, 17, 17, 0.7) 93%
          );

          box-shadow: 9px 9px 28px 0px #114f391a;
          backdrop-filter: blur(15px);

          /* Note: backdrop-filter has minimal browser support */

          border-radius: 8px;

          position: relative;
          .glow2 {
            position: absolute;
            top: 0px;
            right: 0px;
            border-radius: 8px;
          }
          .Bg {
            width: 100%;
            height: 79.59px;
            position: absolute;
            top: 0;
            left: 0;
          }

          .Item {
            position: absolute;
            top: -63px;
            left: 50%;

            max-width: 138px;
            width: auto;
            max-height: 138px;
            height: auto;

            object-fit: contain;

            transform: translateX(-50%);
          }

          .LastItem {
            max-width: 189px;
          }

          .CardContent {
            display: flex;
            flex-direction: column;
            gap: 8px;
            position: absolute;
            bottom: 42px;
            justify-content: center;
            align-items: center;
            width: 96%;
            .Title {
              height: 50px;
              font-family: 'Audiowide';
              font-style: normal;
              font-weight: 400;
              font-size: 20px;
              line-height: 25.5px;
              text-align: center;

              /* One/Gradient */

              background: linear-gradient(
                93.01deg,
                #4895ff 0%,
                #00ffce 31.77%,
                #847be7 67.19%,
                #d629f7 89.06%,
                #ff00ff 100%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;

              @media (max-width: 700px) {
                font-size: 18px;
                line-height: 22.95px;
              }
            }
            .Desc {
              min-height: 50px;
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 300;
              font-size: 18px;
              line-height: 23.4px;
              text-align: center;
              color: #ffffff;
              @media (max-width: 700px) {
                font-size: 15px;
                line-height: 19.5px;
              }
            }
            .Link {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              padding: 5px 12px;
              width: 97px;
              height: 24px;

              background: #00110e;
              box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2),
                inset 0px 2px 3px rgba(0, 255, 206, 0.06);
              border-radius: 4px;

              font-family: 'Poppins';
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 120%;
              /* or 14px */

              text-align: center;
              letter-spacing: 0.085em;
              text-transform: uppercase;

              color: #00ffce;

              opacity: 0.9;
            }
            .glow1 {
              position: absolute;
              bottom: -42px;
              left: 0;
            }
          }
        }
      }
    }

    .WrapperMobile {
      width: 322px;
      margin: 0 auto;
      position: relative;
      display: none;
      @media (max-width: 767.98px) {
        display: block;
      }
      .Card {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        min-height: 252px;

        &Wrapper {
          display: flex;
          flex-direction: column;
          width: 298px;
          height: 252px;
          padding: 30px 10px 42px 10px;
          gap: 10px;

          position: relative;
          .Bg {
            width: 100%;
            height: 79.59px;
            position: absolute;
            top: 0;
            left: 0;
          }

          .Item {
            position: absolute;
            top: 0px;
            left: 50%;
            z-index: 2;
            max-width: 140px;
            width: auto;
            max-height: 130px;
            height: auto;

            object-fit: contain;

            transform: translateX(-50%);
          }

          .LastItem {
            max-width: 189px;
          }

          .CardContent {
            display: flex;
            flex-direction: column;
            gap: 8px;
            position: absolute;
            bottom: 42px;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 187px;
            background: linear-gradient(
              105.64deg,
              rgba(0, 4, 31, 0.6) 0%,
              rgba(17, 17, 17, 0.7) 93%
            );

            box-shadow: 9px 9px 28px 0px #114f391a;
            backdrop-filter: blur(15px);

            /* Note: backdrop-filter has minimal browser support */

            border-radius: 8px;
            .Title {
              font-family: 'Audiowide';
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 22.95px;
              text-align: center;

              /* One/Gradient */

              background: linear-gradient(
                93.01deg,
                #4895ff 0%,
                #00ffce 31.77%,
                #847be7 67.19%,
                #d629f7 89.06%,
                #ff00ff 100%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;

              @media (max-width: 700px) {
                font-size: 18px;
                line-height: 22.95px;
              }
            }
            .Desc {
              min-height: 50px;
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 300;
              font-size: 15px;
              line-height: 19.5px;
              text-align: center;
              color: #ffffff;
              @media (max-width: 700px) {
                font-size: 15px;
                line-height: 19.5px;
              }
            }
            .Link {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              padding: 5px 12px;
              width: 97px;
              height: 24px;

              background: #00110e;
              box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2),
                inset 0px 2px 3px rgba(0, 255, 206, 0.06);
              border-radius: 4px;

              font-family: 'Poppins';
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 120%;
              /* or 14px */

              text-align: center;
              letter-spacing: 0.085em;
              text-transform: uppercase;

              color: #00ffce;

              opacity: 0.9;
            }
            .glow1 {
              position: absolute;
              bottom: 0px;
              left: 0;
            }
            .glow2 {
              position: absolute;
              top: 0px;
              right: 0px;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

:global {
  .disable {
    opacity: 0.3;
    cursor: not-allowed !important;
  }
}
