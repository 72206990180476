.Component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
  padding: 32px 0 0 0;
  background-color: #00110e;

  overflow: hidden;

  .Banner {
    padding: 32px 0 32px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    @media (max-width: 1020px) {
      padding: 40px 0 40px 0;
      gap: 10px;
    }
    .Vector1 {
      width: 100%;
      height: auto;
    }
    .WrapperBox {
      display: flex;
      flex-direction: row;
      gap: 16px;

      .Box {
        padding: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;

        .title {
          color: #00ffce;
          font-family: 'Audiowide';
          font-style: normal;
          font-weight: 400;
          font-size: 42px;
          line-height: 53.55px;
          text-align: center;
        }
        .content {
          color: #ffffff;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          text-align: center;
          height: 60px;
          width: 160.33px;
        }
      }
    }

    @media only screen and (max-width: 1020px) {
      .WrapperBox {
        display: none;
      }
    }
    .WrapperMobile {
      display: none;
      width: 310px;
      z-index: 4;
      @media (max-width:  1020px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .SwiperPartner {
        width: 100vw;
        overflow: unset;
        .slide {
          width: 100vw;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .CardItem {
          height: 94px;
          display: flex;
          flex-direction: row;
          gap: 4px;
          width: 100vw;
          justify-content: center;
          align-items: center;
          .title {
            color: #00ffce;
            font-family: Audiowide;
            font-size: 36px;
            font-weight: 400;
            line-height: 45.9px;
            text-align: center;
          }
          .content {
            color: #ffffff;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: center;
          }
        }
      }
    }
  }
  .Content {
    display: flex;
    flex-direction: column;

    align-items: center;
    gap: 30px;
    width: 100%;

    padding: 40px;

    @media (max-width: 767.98px) {
      padding: 26px;
    }

    .Title {
      font-family: 'Audiowide';
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 40.8px;
      text-align: center center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      height: 87px;
      display: flex;
      justify-content: center;
      align-items: center;

      background: linear-gradient(
        93.01deg,
        #4895ff 0%,
        #00ffce 31.77%,
        #847be7 67.19%,
        #d629f7 89.06%,
        #ff00ff 100%
      );
      background-clip: text;
      text-fill-color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media (max-width: 767.98px) {
        font-size: 20px;
        line-height: 25.5px;
        letter-spacing: 5%;
        height: 50px;
      }
    }

    .List {
      display: flex;
      flex-direction: column;
      gap: 31.47px;
      @media (max-width: 767.98px) {
        gap: 20px;
        padding: 0px 25px;
      }
      &Item {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 27px;
        display: flex;
        flex-direction: row;
        text-align: start;
        letter-spacing: 5%;
        text-transform: uppercase;

        color: #ffffff;

        opacity: 0.9;

        @media (max-width: 767.98px) {
          text-align: center;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;

          text-align: start;
          letter-spacing: 8.5%;
          text-transform: uppercase;
          display: flex;
          flex-direction: column;
          gap: 8px;

          .content {
            text-align: center;
            & p {
              font-weight: 500;
              font-size: 14px;
              line-height: 16.8px;

              text-align: start;
              letter-spacing: 8.5%;
              text-align: center;
              color: #ffffff;

              opacity: 0.9;
            }
          }
        }

        & p {
          display: inline;
          margin-right: 8px;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 27px;

          text-align: start;
          letter-spacing: 5%;
          text-transform: uppercase;
          text-align: center;

          color: #ffffff;

          opacity: 0.9;

          @media (max-width: 767.98px) {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;

            text-align: start;
            letter-spacing: 8.5%;
            text-align: center;
          }
        }

        & span {
          color: #00ffce;
          font-weight: 500;
          @media (max-width: 767.98px) {
            font-weight: 500;
            font-size: 14px;
            line-height: 16.8px;

            text-align: start;
            letter-spacing: 8.5%;
            text-align: center;
            color: #ffffff;

            opacity: 0.9;
          }
        }
      }
    }
  }

  .Card {
    display: flex;
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;

    @media (max-width: 1020px) {
      flex-direction: column;
      padding: 0 16px;
    }

    &Item {
      width: 325px;
      height: 143px;

      @media (max-width: 767.98px) {
        width: 100%;
      }
    }
  }

  .Agenda {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
    gap: 50px;
    max-width: 1011px;

    @media (max-width: 767.98px) {
      padding: 30px 25px;
      margin: 0px 25px;
      max-width: 350px;
    }

    &Title {
      text-align: center;
      font-family: Audiowide;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 40.8px;
      letter-spacing: 10%;

      background: linear-gradient(
        135deg,
        #4895ff 0%,
        #00ffce 31.77%,
        #847be7 67.19%,
        #d629f7 89.06%,
        #f0f 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &Content {
      display: flex;
      width: calc(100% - 100px);
      padding: 40px 50px;
      gap: 50px;

      border-radius: 14px;
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.4) 0%,
          rgba(0, 0, 0, 0.4) 100%
        ),
        linear-gradient(
          173deg,
          rgba(0, 182, 222, 0.2) 0%,
          rgba(0, 92, 113, 0.2) 28.65%,
          rgba(0, 0, 0, 0.2) 100%
        );
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
        5px 5px 6px 0px rgba(136, 255, 243, 0.1) inset,
        0px -2px 3px 0px rgba(95, 141, 252, 0.17) inset;
      backdrop-filter: blur(15px);
      position: relative;
      overflow: hidden;

      @media (max-width: 1000px) {
        flex-direction: column;
        gap: 22px;
        padding: 30px 25px;
        width: calc(100% - 50px);
      }

      & .Bg1 {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      & .Bg2 {
        position: absolute;
        bottom: 0;
        right: 0;
      }

      & .Bg3 {
        position: absolute;
        bottom: 0;
        left: 0;
      }

      & .Col {
        display: flex;
        flex-direction: column;
        gap: 22px;

        z-index: 2;
        width: 100%;
        & .Wrapper {
          display: flex;
          flex-direction: column;
          gap: 5px;

          & .Title {
            color: #00ffce;
            font-family: Audiowide;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */
            text-transform: uppercase;
            opacity: 90%;
          }

          & .Decs {
            color: #fff;
            font-family: Poppins;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 21px */
            opacity: 0.9;
            letter-spacing: 8.5%;
            text-wrap: wrap;
            @media (max-width: 767.98px) {
            }
          }
        }
      }
    }
  }

  .Btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220.3px;
    height: 43.23px;
    background-image: url('../../../assets/images/info/Union\ \(1\).png');

    font-family: 'Aileron';
    font-style: italic;
    font-weight: 700;
    font-size: 18.67px;
    line-height: 22.4px;

    color: #ffffff;
    position: relative;
    margin-bottom: 40px;
    @media (max-width: 767.98px) {
      margin-bottom: 0px;
    }

    &::before {
      content: ' ';
      width: 177px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      background: linear-gradient(
        93.01deg,
        #4895ff 0%,
        #00ffce 31.77%,
        #847be7 67.19%,
        #d629f7 89.06%,
        #ff00ff 100%
      );
      opacity: 0.4;
      filter: blur(15px);
      transform: skewX(-20deg) translateX(-50%);
      z-index: -1;
    }
  }
}
