.Component {
  display: flex;
  flex-direction: column;
  background-color: #00110e;
  overflow: hidden;
  padding: 40px 0 40px 0;
  gap: 32px;
  position: relative;
  width: 100%;
  @media (max-width: 900px) {
    gap: 30;
  }
  &::before {
    content: ' ';
    height: 354px;
    width: 100%;

    background: linear-gradient(
      180deg,
      #00110e 16.67%,
      rgba(0, 17, 14, 0) 100%
    );

    position: absolute;
    top: 0;
    left: 0;

    z-index: 3;
  }
  .Content {
    z-index: 4;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
    .Title {
      text-align: center;
      font-size: 32px;
      font-family: Audiowide;
      font-style: normal;
      font-weight: 400;
      line-height: 40.08px;
      text-transform: uppercase;

      background-clip: text;
      text-fill-color: transparent;
      background: linear-gradient(
        93.01deg,
        #4895ff 0%,
        #00ffce 31.77%,
        #847be7 67.19%,
        #d629f7 89.06%,
        #ff00ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media (max-width: 900px) {
        font-size: 20px;
        line-height: 25.5px;
        letter-spacing: 10%;
      }
    }
    .Text {
      font-size: 32px;
      line-height: 48px;
      color: white;
      font-family: 'Poppins';
      @media (max-width: 900px) {
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 10%;
      }
    }
  }
  .Wrap {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .Wrapper {
    width: 1222px;
    height: 667px;
    border-radius: 13.41px;
    position: relative;
    box-shadow: 0px 4px 4px 0px #00000040;
    box-shadow: 5px 5px 6px 0px #88fff31a inset;

    box-shadow: 0px -2px 3px 0px #5f8dfc2b inset;
    padding: 1px;

    background-image: linear-gradient(
      93.01deg,
      #4895ff 0%,
      #00ffce 31.77%,
      #847be7 67.19%,
      #d629f7 89.06%,
      #ff00ff 100%
    );
    @media (max-width: 900px) {
      width: 90%;
      max-width: 567px;
    }
    .Box {
      width: 100%;
      height: 100%;
      border-radius: inherit;
      background-color: #101010;
      @media (max-width: 900px) {
        width: 100%;
        height: 100%;
      }

      .vector1 {
        z-index: 2;
        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: 13.41px;

        @media (max-width: 900px) {
          width: 53.49px;
          height: 20.71px;
        }
      }
      .vector2 {
        z-index: 2;
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 13.41px;

        @media (max-width: 900px) {
          width: 172.31px;
          height: 164.45px;
        }
      }
      .vector3 {
        z-index: 0;
        position: absolute;

        top: 0;
        right: 20%;
        @media (max-width: 900px) {
          width: 53.49px;
          height: 20.71px;
        }
      }
    }

    .Content {
      padding: 32px;
      display: flex;
      gap: 32px;
      align-items: start;
      position: absolute;
      .Row1 {
        display: flex;
        flex-direction: column;
        gap: 6px;
        .Txt {
          font-family: Poppins;
          font-size: 20px;
          font-weight: 400;
          line-height: 30px;
          text-align: left;
          color: white;
          @media (max-width: 900px) {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            text-align: left;
          }
        }
        .ContentBox {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .WrapText {
            font-family: Poppins;
            font-size: 62px;
            font-weight: 400;
            line-height: 93px;
            text-align: left;
            @media (max-width: 900px) {
              font-family: Poppins;
              font-size: 42px;
              font-weight: 400;
              line-height: 63px;
              text-align: left;
            }
          }
        }
      }
      .Row2 {
        display: flex;
        gap: 16px;
        flex-direction: column;
        .Contain {
          display: flex;
          flex-direction: row;
          gap: 16px;
          align-items: center;
          .text {
            font-family: Poppins;
            font-size: 17px;
            font-weight: 400;
            line-height: 25.5px;
            text-align: left;
            @media (max-width: 900px) {
              font-family: Poppins;
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
              text-align: left;
            }
          }
          .image {
            width: 25px;
            height: 25px;
            object-fit: contain;
            @media (max-width: 900px) {
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }

    .btn {
      margin-top: 18px;
      width: 1158px;
      height: 74px;
      gap: 10px;
      border-radius: 7px;
      border: 1px solid;
      display: flex;
      justify-content: center;
      align-items: center;
      border-image-source: linear-gradient(
        91.22deg,
        #2b4a54 1.44%,
        #094d5b 103.16%
      );
      background: linear-gradient(
          270.46deg,
          rgba(2, 6, 23, 0.3) -1.49%,
          rgba(0, 0, 0, 0.3) 106.05%
        ),
        linear-gradient(90deg, #11edc2 0%, #01cba4 100%);
      font-family: Poppins;
      font-size: 28px;
      font-weight: 400;
      line-height: 42px;
      text-align: center;
      @media (max-width: 900px) {
        width: 100%;
        height: 62px;
        right: 5%;
        font-family: Poppins;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        margin-top: 0px;
      }
    }
  }
  .WrapperContent {
    width: 1222px;
    @media (max-width: 900px) {
      width: 90%;
      max-width: 567px;
    }
    p {
      font-family: Poppins;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      text-align: left;
      color: white;
      @media (max-width: 900px) {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
      }
      span {
        a {
          font-family: Poppins;
          font-size: 20px;
          font-weight: 400;
          line-height: 30px;
          text-align: left;
          color: #00ffce;
          text-decoration-line: underline;
          @media (max-width: 900px) {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            text-align: left;
            color: #00ffce;
            text-decoration-line: underline;
          }
        }
      }
    }
  }
}
