.App {
  background: linear-gradient(
    85.7deg,
    #073371 -2.63%,
    #001e18 27.76%,
    #270027 93.01%
  );
  position: relative;

  overflow: hidden;
}
