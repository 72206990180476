.Component {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;

  z-index: 100;

  .Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    padding: 41px 120px;
    margin: 0 auto;

    transition: padding 0.6s ease;

    @media (max-width: 1240px) {
      padding: 41px 60px;
    }

    @media (max-width: 1160px) {
      padding: 41px 27px;
    }

    .TabBar {
      display: none;

      @media (max-width: 1160px) {
        display: inline-block;
      }
    }

    .Logo {
      @media (max-width: 1160px) {
        width: 189px;
        height: 31px;
      }
    }

    .Nav {
      display: flex;
      align-items: center;
      gap: 32px;

      &Item {
        & a {
          font-family: 'Audiowide';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          cursor: pointer;

          color: #fff;
          transition: color 0.3s ease;

          &:hover {
            color: #00ffce;
          }
        }

        @media (max-width: 1160px) {
          display: none;
        }
      }
    }

    .Btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 189px;
      height: 43.23px;
      background-image: url('../../assets/images/banner/Union.png');

      font-family: 'Poppins';
      font-style: italic;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;

      color: #ffffff;
      position: relative;

      @media (max-width: 1160px) {
        display: none;
      }

      &::before {
        content: ' ';
        width: 177px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        background: linear-gradient(
          93.01deg,
          #4895ff 0%,
          #00ffce 31.77%,
          #847be7 67.19%,
          #d629f7 89.06%,
          #ff00ff 100%
        );
        opacity: 0.4;
        filter: blur(15px);
        transform: skewX(-20deg) translateX(-50%);
        z-index: -1;
      }
    }
  }
}

.Blur {
  height: 0;
  width: 100%;

  background: linear-gradient(
    150deg,
    rgba(6, 20, 18, 0.7) 32.05%,
    rgba(11, 38, 33, 0.7) 102%
  );
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);

  transition: height 0.6s ease;
  position: absolute;
  top: 0;
  left: 0;

  z-index: -1;
}

.Show {
  height: 100% !important;
}

.ComponentShow {
  padding: 20px 120px !important;

  @media (max-width: 1240px) {
    padding: 20px 60px !important;
  }

  @media (max-width: 1160px) {
    padding: 20px 27px !important;
  }
}

.Drawn {
  height: 100vh;
  width: 100vw !important;
  max-width: 360px !important;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(
      109.2deg,
      rgba(0, 182, 222, 0.4) -1.74%,
      rgba(0, 92, 113, 0.4) 29.29%,
      rgba(0, 0, 0, 0.4) 106.6%
    ) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 4px 20px rgba(255, 255, 255, 0.12),
    inset 0px -2px 3px rgba(95, 141, 252, 0.17),
    inset 5px 5px 6px rgba(136, 255, 243, 0.1);
  backdrop-filter: blur(7px);

  border-radius: 4px;
}

.Mobile {
  padding: 60px 60px 0px 60px;
  position: relative;

  .Icon {
    position: absolute;
    top: 21px;
    left: 22px;
  }

  .Wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .Nav {
      display: flex;
      flex-direction: column;
      gap: 30px;
      padding: 0;

      &Item {
        & a {
          font-family: 'Audiowide';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;

          color: #ffffff;
          transition: color 0.3s ease;
          cursor: pointer;

          opacity: 0.9;

          &:hover {
            color: #00ffce;
          }
        }
      }
    }

    .Btn {
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateX(-18px);
      width: 177px;
      height: 42px;
      background-image: url('../../assets/images/background-btn-header.png');
      font-family: 'Poppins';
      font-style: italic;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;

      color: #ffffff;

      position: relative;

      &::before {
        content: ' ';
        width: 177px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        background: linear-gradient(
          93.01deg,
          #4895ff 0%,
          #00ffce 31.77%,
          #847be7 67.19%,
          #d629f7 89.06%,
          #ff00ff 100%
        );
        opacity: 0.4;
        filter: blur(15px);
        transform: skewX(-20deg) translateX(-50%);
        z-index: -1;
      }
    }
  }
}

.Active {
  color: #00ffce !important;
}
