.Component {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #00110e;
  overflow: hidden;
  padding: 44px 0 44px 0;
  gap: 32px;
  position: relative;
  width: 100%;
  &::before {
    content: ' ';
    height: 354px;
    width: 100%;

    background: linear-gradient(
      180deg,
      #00110e 16.67%,
      rgba(0, 17, 14, 0) 100%
    );

    position: absolute;
    top: 0;
    left: 0;

    z-index: 3;
  }

  .BG {
    width: 100%;
    height: 160%;
    object-fit: cover;

    position: absolute;
    top: -252.36px;
    left: 50%;

    transform: translateX(-50%);

    z-index: 1;

    @media (min-width: 1440px) {
      object-fit: unset;
    }
  }

  .Content {
    z-index: 4;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
    .Title {
      text-align: center;
      font-size: 32px;
      font-family: Audiowide;
      font-style: normal;
      font-weight: 400;
      line-height: 40.08px;
      text-transform: uppercase;

      background-clip: text;
      text-fill-color: transparent;
      background: linear-gradient(
        93.01deg,
        #4895ff 0%,
        #00ffce 31.77%,
        #847be7 67.19%,
        #d629f7 89.06%,
        #ff00ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media (max-width: 767.98px) {
        font-size: 20px;
        line-height: 25.5px;
        letter-spacing: 10%;
      }
    }
    .Text {
      font-size: 32px;
      line-height: 48px;
      color: white;
      font-family: 'Poppins';
      @media (max-width: 767.98px) {
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 10%;
      }
    }
  }

  .WrapperDesktop {
    z-index: 4;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    flex-direction: column;
    @media (max-width: 767.98px) {
      display: none;
    }
    .Col {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: row;
      gap: 30px;
      .CardItem {
        height: 94px;
        display: flex;
        flex-direction: row;
        gap: 16px;
        .WrapImage {
          width: 216px;
          display: flex;

          align-items: center;
          border-radius: 8px;
        }
        .Info {
          display: flex;
          flex-direction: column;
          text-align: right;
          width: 160px;
          .Name {
            font-family: Poppins;
            font-size: 12px;
            font-weight: 700;
            line-height: 18px;
            letter-spacing: -0.02em;
            text-align: right;
            color: #00ffce;
          }
          .Desc {
            font-family: Poppins;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.02em;
            text-align: right;
            color: white;
          }
        }
      }
    }
  }

  .WrapperMobile {
    display: none;
    width: 310px;
    z-index: 4;
    @media (max-width: 767.98px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .SwiperPartner {
      width: 100vw;
      overflow: unset;
      .slide {
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .CardItem {
        height: 94px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        width: 100vw;
        justify-content: center;
        align-items: center;

        .WrapImage {
          width: 159.28px;
          display: flex;

          align-items: flex-start;
          border-radius: 8px;
          .Image {
            width: 159.28px;
            object-fit: cover;
          }
        }
        .Info {
          display: flex;
          flex-direction: column;
          text-align: right;
          width: 160px;
          .Name {
            font-family: Poppins;
            font-size: 11px;
            font-weight: 700;
            line-height: 16.5px;
            letter-spacing: -0.02em;
            text-align: right;
            color: #00ffce;
          }
          .Desc {
            font-family: Poppins;
            font-size: 10px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: -2%;
            text-align: right;
            color: white;
          }
        }
      }
    }
  }
}
