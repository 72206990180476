.Component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 675px;

  background-image: url(../../../assets/images/banner.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  position: relative;

  @media (max-width: 767px) {
    height: 541px;
    background-image: url(../../../assets/images/banner/bg-banner-mobile.png);
    background-size: cover;
    justify-content: start;
  }

  .Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    max-width: 572px;

    position: relative;
    z-index: 2;

    @media (max-width: 767px) {
      max-width: 318px;
      margin-bottom: 10px;
      margin-top: 115.33px;
    }

    .Text {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 36px;
      line-height: 120%;

      text-align: center;
      letter-spacing: 11.88px;
      text-transform: uppercase;

      color: #ffffff;

      @media (max-width: 767px) {
        font-size: 18px;
      }
    }

    .TextGradient {
      font-family: 'Audiowide';
      font-style: normal;
      font-weight: 400;
      font-size: 56px;
      line-height: 120%;

      text-align: center;
      letter-spacing: 0.1em;

      background-clip: text;
      text-fill-color: transparent;
      background: linear-gradient(
        93.01deg,
        #4895ff 0%,
        #00ffce 31.77%,
        #847be7 67.19%,
        #d629f7 89.06%,
        #ff00ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media (max-width: 767px) {
        font-size: 30px;
      }

      & span {
        text-shadow: #00ffcca2 1px 0 20px;
      }
    }

    .TextLocation {
      margin-top: 18px;
      font-family: 'Audiowide';
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 38px;
      text-align: center;
      letter-spacing: -0.02em;

      color: #00ffce;
      text-shadow: #00ffcca2 1px 0 20px;

      & span {
        font-family: 'Audiowide';
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 38px;
        text-align: center;
        letter-spacing: -0.02em;

        color: #00ffce;
        text-shadow: #00ffcca2 1px 0 20px;

        @media (max-width: 767px) {
          display: none;
        }
      }

      @media (max-width: 767px) {
        font-size: 16px;
        max-width: 141px;
        line-height: 150%;
      }
    }
  }

  .Chapter2Desktop {
    display: block;
    @media (max-width: 767px) {
      display: none;
    }
  }

  .Chapter2Mobile {
    display: none;
    @media (max-width: 767px) {
      display: block;
    }
  }

  .WrapperLocationAndTime {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 0px;

    @media (max-width: 767px) {
      margin-top: 30px;
      flex-direction: column;
      gap: 0px;
    }
    .Location {
      font-family: 'Audiowide';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 150%;
      text-align: center;
      letter-spacing: -2%;

      color: #00ffce;
      @media (max-width: 767px) {
        font-size: 15px;
      }
    }
    .Time {
      font-family: 'Audiowide';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 150%;
      text-align: center;
      letter-spacing: -2%;

      color: #00ffce;
      @media (max-width: 767px) {
        font-size: 15px;
      }
    }
  }

  .Wrapper {
    display: flex;
    flex-direction: column;
    width: 100vw;
    position: absolute;
    bottom: -228px;

    @media (max-width: 990px) {
      bottom: -118px;
      gap: 30px;
    }

    .CountDown {
      display: flex;
      gap: 24px;
      justify-content: center;
      align-items: center;
      @media (max-width: 990px) {
        gap: 10px;
      }

      & div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 121px;
        height: 117px;

        border-radius: 14px;

        position: relative;
        z-index: 1;

        @media (max-width: 990px) {
          width: 68px;
          height: 66px;
        }

        & .Blur {
          width: 100%;
          height: 100%;
          padding: 1px;
          position: absolute;
          top: 0;
          left: 0;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
            0px 4px 20px rgba(255, 255, 255, 0.12),
            inset 0px -2px 3px rgba(95, 141, 252, 0.17),
            inset 5px 5px 6px rgba(136, 255, 243, 0.1);

          z-index: 1;

          .Bg {
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            padding: 1px;
            position: absolute;
            top: 2;
            left: 2;
            background-color: #00110e;

            z-index: 1;
          }

          .Filter {
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            padding: 1px;
            position: absolute;
            top: 2;
            left: 2;
            background: linear-gradient(
              109.2deg,
              rgba(0, 182, 222, 0.2) -1.74%,
              rgba(0, 92, 113, 0.2) 29.29%,
              rgba(0, 0, 0, 0.2) 106.6%
            );
            backdrop-filter: blur(7px);
            z-index: 2;
          }
        }

        & span {
          font-family: 'Audiowide';
          font-style: normal;
          font-weight: 400;
          font-size: 50px;
          line-height: 120%;

          text-align: center;

          color: #00ffce;

          position: relative;
          z-index: 2;

          @media (max-width: 990px) {
            font-size: 30px;
          }
        }

        & p {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 120%;

          text-align: center;
          letter-spacing: 0.085em;
          text-transform: uppercase;

          color: #ffffff;

          opacity: 0.9;
          position: relative;
          z-index: 2;

          @media (max-width: 990px) {
            font-size: 9px;
          }
        }
      }

      .Day {
        .Blur {
          background: linear-gradient(93.01deg, #4895ff 0%, #00ffce 100%);
        }
      }

      .Hour {
        .Blur {
          background: linear-gradient(93.01deg, #00ffce 0%, #847be7 100%);
        }
      }

      .Minute {
        .Blur {
          background: linear-gradient(93.01deg, #847be7 57.29%, #d629f7 100%);
        }
      }

      .SECOND {
        .Blur {
          background: linear-gradient(93.01deg, #d629f7 0%, #ff00ff 100%);
        }
      }
    }

    .Btn {
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 2;
      background-image: url(../../../assets/images/banner/vctor.png);
      width: 100vw;
      height: 167px;
      object-fit: fill;
      background-repeat: no-repeat;
      background-position: center center;
      align-items: center;

      @media (max-width: 990px) {
        height: 58px;
        background-image: none;
      }
      &Item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 229px;
        height: 58px;
        border-radius: 100px;
        font-family: 'Aileron';
        font-weight: 700;
        font-size: 22px;
        line-height: 26.4px;
        position: relative;
        background-clip: padding-box;
        background-color: #00110e;
        text-transform: uppercase;

        border: 2px solid transparent;
        &:before {
          content: '';
          position: absolute;
          inset: 0;
          z-index: -1;
          margin: -2px;
          background-image: linear-gradient(
            269.59deg,
            #4895ff -16.78%,
            #00ffce 22.9%,
            #847be7 67.14%,
            #d629f7 94.46%,
            #ff00ff 108.12%
          );
          border-radius: inherit;
        }
      }
    }
  }
}
