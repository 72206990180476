.Component {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #00110e;
  overflow: hidden;
  padding: 176.44px 0 40px 0;
  gap: 32px;
  @media (max-width: 767.98px) {
    padding: 40px 0 40px 0;
  }
  .imageTrans {
    width: 96px;
    height: 75px;

    @media (max-width: 767.98px) {
      height: 70px;
    }
  }
  .imageStra {
    width: 142px;
    height: 115px;

    @media (max-width: 767.98px) {
      width: 138px;
      height: 111.6px;
    }
  }
  .CenterTrans {
    margin-top: 24px;
    @media (max-width: 767.98px) {
      padding: 0px;
    }
  }
  .PartnerWrapper {
    display: flex;
    justify-content: center;
    gap: 0px;
    @media (max-width: 767.98px) {
      flex-direction: column;
      gap: 32px;
    }
    .imageCohost {
      height: 138.91px;

      @media (max-width: 767.98px) {
        height: 70px;
      }
    }
    .imageCohost2 {
      height: 138.91px;

      @media (max-width: 767.98px) {
        height: 70px;
      }
    }
    .imageCo {
      width: 338.4px;
      height: 55.8px;

      @media (max-width: 767.98px) {
        height: 45px;
        width: 274.35px;
      }
    }
    .CenterHost {
      padding-left: 29.94px;
      padding-right: 29.94px;
      @media (max-width: 767.98px) {
        padding: 0px;
      }
    }
    .CoHost {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 32px;
      max-width: 1200px;
      margin: 0 auto;
      width: 478px;
      z-index: 2;

      @media (max-width: 767.98px) {
        gap: 40px;
      }

      &Title {
        text-align: center;
        font-size: 23px;
        font-family: Audiowide;
        font-style: normal;
        font-weight: 400;
        line-height: 29.32px;
        letter-spacing: 10%;
        text-transform: uppercase;

        background-clip: text;
        text-fill-color: transparent;
        background: linear-gradient(
          93.01deg,
          #4895ff 0%,
          #00ffce 31.77%,
          #847be7 67.19%,
          #d629f7 89.06%,
          #ff00ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media (max-width: 767.98px) {
          font-size: 20px;
          line-height: 25.5px;
          letter-spacing: 10%;
        }
      }

      .Center {
        padding: 46.17px 50.72px;

        @media (max-width: 767.98px) {
          padding: 0px;
        }
      }
      .image {
        height: 62px;
        width: 376px;
        @media (max-width: 767.98px) {
          height: 70px;
          width: 260px;
          object-fit: contain;
        }
      }
    }
  }

  .Sponsors {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;

    padding: 29px 0 31px 0;
    max-width: 1200px;
    margin: 0 auto;
    width: calc(100% - 52px);

    position: relative;
    @media (max-width: 767.98px) {
      padding: 40px 0 0 0;
    }
    .Background {
      position: absolute;
      top: -88px;
      left: 50%;
      transform: translateX(-50%);
      width: 542.03px;
      height: 550px;
      display: block;

      @media (max-width: 767.98px) {
        display: none;
      }
    }
    .LogoBox {
      display: flex;
      flex-direction: row;
      gap: 62px;
      @media (max-width: 767.98px) {
        flex-direction: column;
        gap: 30px;
      }
    }
    .Text2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      margin-bottom: 20px;
      width: 470px;
      z-index: 2;
      @media (max-width: 767.98px) {
        width: 370px;
      }
    }
    & .Text {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      margin-bottom: 20px;

      z-index: 2;

      & .SubTitle {
        color: #fff;
        text-align: center;
        font-size: 25px;
        font-family: Audiowide;
        font-style: normal;
        font-weight: 400;
        line-height: 31.87px;
        letter-spacing: -2%;

        @media (max-width: 767.98px) {
          font-size: 26px;
          line-height: 33.15px;
          letter-spacing: -2%;
        }
      }

      & .Title {
        font-family: 'Audiowide';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 41px;

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        background-clip: text;
        text-fill-color: transparent;
        background: linear-gradient(
          93.01deg,
          #4895ff 0%,
          #00ffce 31.77%,
          #847be7 67.19%,
          #d629f7 89.06%,
          #ff00ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        z-index: 2;

        @media (max-width: 767.98px) {
          text-align: center;
          font-size: 24px;
          font-family: Audiowide;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 10%;
          text-transform: uppercase;
        }
      }
    }

    .ImageDiamond {
      width: 578.2px;
      height: 148.26px;
      padding: 40.87px 16px;
      object-fit: cover;

      z-index: 2;

      @media (max-width: 767.98px) {
        height: 70.31px;
        width: 274.22px;
        padding: 0;
      }
    }
  }
  .imagePhongVu {
    width: 559.5px;
    height: 87px;
    @media (max-width: 767.98px) {
      width: 360px;
      height: 43px;
    }
  }
  .LogoListSponsor {
    display: flex;
    flex-direction: row;
    @media (max-width: 767.98px) {
      flex-direction: column;
      gap: 67px;
      justify-content: center;
      align-items: center;
    }
    .ImageDiamond1 {
      margin-right: 33.53px;
      z-index: 2;
      width: 225.24px;
      height: 87.38px;
      @media (max-width: 767.98px) {
        width: 181.82px;
        height: 70.54px;
        margin-right: 0px;
      }
    }
    .ImageDiamond2 {
      margin-right: 51.59px;
      margin-left: 47.49px;
      z-index: 2;
      width: 416.5px;
      height: 111.19px;
      @media (max-width: 767.98px) {
        width: 309.82px;
        height: 82.71px;
        margin-right: 0px;
        margin-left: 0px;
      }
    }
    .ImageDiamond3 {
      margin-left: 51.67px;
      z-index: 2;
      width: 181.99px;
      height: 104.61px;
      @media (max-width: 767.98px) {
        width: 149.82px;
        height: 86.12px;
        margin-left: 0px;
      }
    }
    .Line {
      height: 108.71px;
      width: 1px;
      background: #ffffff;
      opacity: 60%;
      z-index: 2;
      display: block;
      @media (max-width: 767.98px) {
        display: none;
      }
    }
  }
  .Title {
    width: fit-content;
    font-family: 'Audiowide';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 35.7px;

    text-align: center;
    letter-spacing: 10%;
    text-transform: uppercase;

    background: linear-gradient(
      93.01deg,
      #4895ff 0%,
      #00ffce 31.77%,
      #847be7 67.19%,
      #d629f7 89.06%,
      #ff00ff 100%
    );
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 767.98px) {
      font-size: 22px;
      line-height: 28.05px;
    }
  }
  .ImageGold {
    padding: 60.01px 16px;
    width: 183.92px;
    height: 109.98px;
    object-fit: cover;

    @media (max-width: 767.98px) {
      height: 67.5px;
      width: 200.57px;
      padding: 0px;
    }
  }
  .Wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 32px;
    isolation: isolate;
    margin: 0 auto;

    height: 63px;
    width: 100%;
    max-width: 1200px;

    @media (max-width: 575.98px) {
      padding: 0 0 26px 0;
    }

    .Center {
      display: block;
      align-items: center;
      justify-content: center;
      .ImageSilver {
        height: 180px;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: auto;
        padding-bottom: auto;
        object-fit: cover;
        width: auto;
      }
    }
  }
}

.LogoList {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  width: calc(100% - 52px);
  gap: 50px;

  @media (max-width: 575.98px) {
    width: fit-content;
  }
}

.LogoListDesktop {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  width: calc(100% - 52px);
  gap: 50px;

  @media (max-width: 767.98px) {
    display: none;
  }
  .ImageSilver {
    height: 180px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: auto;
    padding-bottom: auto;
    object-fit: contain;
    max-width: 307.88px;
  }
  .ImageBronze {
    height: 120px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: auto;
    padding-bottom: auto;
    object-fit: contain;
    max-width: 230.91px;
  }
  .ImageEx {
    height: 95px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: auto;
    padding-bottom: auto;
    object-fit: contain;
    max-width: 139px;
  }
}

.LogoListMobile {
  display: none;
  width: 100vw;
  @media (max-width: 767.98px) {
    display: block;

    .slide {
      .Center {
        width: 100vw;
        display: flex;
        justify-content: center;
      }
      .card {
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .ImageSilver {
        height: 68px;
        width: auto;
      }
      .ImageEx {
        height: auto;
        width: auto;
        object-fit: contain;
      }
      .ImageMedia {
        max-height: 65.6px;
        width: auto;
        object-fit: contain;
      }
      .ImagePartner {
        max-height: 50px;
        width: auto;
        object-fit: contain;
        padding-right: 0px;
      }
    }
  }
}
.CoHost4 {
  width: 75px;
  height: 75px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.MaxWidth {
  max-width: 1200px;
  margin: 0 auto;
  width: calc(100% - 52px);
}
.Bronze {
  margin-top: 102px;
}
.PartnerWrapperDesktop {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px 50px;

  @media (max-width: 767.98px) {
    display: none;
  }

  &Item {
    display: flex;
    align-items: center;
    justify-content: center;
    .ImageMe5 {
      width: 200px;
      height: 61.14px;

      padding: 29.43px 17px;

      object-fit: contain;
    }
    .ImageMe6 {
      width: 126.43px;
      height: 62.46px;

      padding: 28.77px 53.79px;

      object-fit: contain;
    }
    .ImageMe7 {
      width: 180px;
      height: 120px;

      object-fit: contain;
    }
    .ImageMe {
      height: 95px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: auto;
      padding-bottom: auto;
      object-fit: contain;
      max-width: 125.63px;
    }
    .ImageMe3 {
      height: 95px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: auto;
      padding-bottom: auto;
      object-fit: contain;
      width: 64px;
    }
    .ImagePartner2 {
      height: 95px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: auto;
      padding-bottom: auto;
      object-fit: contain;
      width: 109.52px;
    }
    .ImagePartner3 {
      height: 95px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: auto;
      padding-bottom: auto;
      object-fit: contain;
      width: 81px;
    }
    .ImagePartner4 {
      height: 95px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: auto;
      padding-bottom: auto;
      object-fit: contain;
      width: 119px;
    }
    .ImagePartner5 {
      height: 95px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: auto;
      padding-bottom: auto;
      object-fit: contain;
      width: 146px;
    }
    .ImagePartner5 {
      height: 95px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: auto;
      padding-bottom: auto;
      object-fit: contain;
      width: 146px;
    }
    .ImagePartner6 {
      height: 95px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: auto;
      padding-bottom: auto;
      object-fit: contain;
      width: 100px;
    }
    .ImagePartner7 {
      height: 95px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: auto;
      padding-bottom: auto;
      object-fit: contain;
      width: 77.65px;
    }
    .ImagePartner8 {
      height: 95px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: auto;
      padding-bottom: auto;
      object-fit: contain;
      width: 128px;
    }
    .ImagePartner9 {
      height: 95px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: auto;
      padding-bottom: auto;
      object-fit: contain;
      width: 61px;
    }
    .ImagePartner10 {
      height: 95px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: auto;
      padding-bottom: auto;
      object-fit: contain;
      width: 106px;
    }
    .ImagePartner11 {
      height: 95px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: auto;
      padding-bottom: auto;
      object-fit: contain;
      width: 76.74px;
    }
    .ImagePartner12 {
      height: 95px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: auto;
      padding-bottom: auto;
      object-fit: contain;
      width: 90px;
    }
    .ImagePartner13 {
      height: 95px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: auto;
      padding-bottom: auto;
      object-fit: contain;
      width: 235px;
    }
    .ImagePartner14 {
      height: 95px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: auto;
      padding-bottom: auto;
      object-fit: contain;
      width: 126px;
    }
    .ImagePartner15 {
      height: 95px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: auto;
      padding-bottom: auto;
      object-fit: contain;
      width: 155px;
    }
    .ImagePartner16 {
      height: 95px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: auto;
      padding-bottom: auto;
      object-fit: contain;
      width: 81.53px;
    }
    .ImagePartner17 {
      height: 95px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: auto;
      padding-bottom: auto;
      object-fit: contain;
      width: 186px;
    }
    .ImagePartner18 {
      height: 95px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: auto;
      padding-bottom: auto;
      object-fit: contain;
      width: 128px;
    }
    .ImagePartner19 {
      height: 95px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: auto;
      padding-bottom: auto;
      object-fit: contain;
      width: 97px;
    }
    // .ImagePartner20 {
    //   height: 120px;
    //   padding-left: 16px;
    //   padding-right: 16px;
    //   padding-top: auto;
    //   padding-bottom: auto;
    //   object-fit: contain;
    //   width: 89.43px;
    // }
    // .ImagePartner21 {
    //   height: 120px;
    //   padding-left: 16px;
    //   padding-right: 16px;
    //   padding-top: auto;
    //   padding-bottom: auto;
    //   object-fit: contain;
    //   width: 88px;
    // }
    // .ImagePartner22 {
    //   height: 120px;
    //   padding-left: 16px;
    //   padding-right: 16px;
    //   padding-top: auto;
    //   padding-bottom: auto;
    //   object-fit: contain;
    //   width: 85.08px;
    // }
    // .ImagePartner23 {
    //   height: 120px;
    //   padding-left: 16px;
    //   padding-right: 16px;
    //   padding-top: auto;
    //   padding-bottom: auto;
    //   object-fit: contain;
    //   width: 109.35px;
    // }
    // .ImagePartner24 {
    //   height: 120px;
    //   padding-left: 16px;
    //   padding-right: 16px;
    //   padding-top: auto;
    //   padding-bottom: auto;
    //   object-fit: contain;
    //   width: 98.65px;
    // }
    // .ImagePartner25 {
    //   height: 120px;
    //   padding-left: 16px;
    //   padding-right: 16px;
    //   padding-top: auto;
    //   padding-bottom: auto;
    //   object-fit: contain;
    //   width: 111.84px;
    // }

    .ImagePartner20 {
      height: 42px;
      padding: 39px 44px;
      object-fit: contain;
      width: 92px;
    }
    .ImagePartner21 {
      height: 54px;
      padding: 33px 44.5px;
      object-fit: contain;
      width: 91px;
    }
    .ImagePartner22 {
      height: 56px;
      padding: 32px 44.5px;
      object-fit: contain;
      width: 91px;
    }
    .ImagePartner23 {
      height: 38px;
      padding: 41px 29px;
      object-fit: contain;
      width: 122px;
    }
    .ImagePartner24 {
      height: 33.46px;
      padding: 43.27px 45px;
      object-fit: contain;
      width: 90px;
    }
    .ImagePartner25 {
      height: 30px;
      padding: 45px 39px;
      object-fit: contain;
      width: 102px;
    }
    .ImagePartner26 {
      padding: 46px 22px;
      object-fit: contain;
      width: 136px;
      height: 28px;
    }
    .ImagePartner27 {
      padding: 21px 50px;
      object-fit: contain;
      width: 80px;
      height: 78px;
    }
    .ImagePartner28 {
      padding: 34.25px 54px;
      object-fit: contain;
      width: 72px;
      height: 51.5px;
    }
    .ImagePartner29 {
      padding: 37.97px 35px;
      object-fit: contain;
      width: 110px;
      height: 44.07px;
    }
    .ImagePartner30 {
      padding: 29px 33px;
      object-fit: contain;
      width: 114px;
      height: 62px;
    }
    .ImagePartner31 {
      padding: 29.31px 56px;
      object-fit: contain;
      width: 68px;
      height: 61.38px;
    }
    .ImagePartner32 {
      padding: 27px 63px;
      object-fit: contain;
      width: 54px;
      height: 66px;
    }
    .ImagePartner33 {
      height: 96px;
      padding: 12px 41px;
      object-fit: contain;
      width: 98px;
    }
    .ImagePartner34 {
      padding: 36px 14px;
      object-fit: contain;
      width: 152px;
      height: 48px;
    }
    .ImagePartner35 {
      width: 140px;
      height: 27.4px;

      padding: 46.3px 20px;
      object-fit: contain;
    }
    .ImagePartner36 {
      width: 116px;
      height: 40px;

      padding: 40px 32px;
      object-fit: contain;
    }
    .ImagePartner37 {
      width: 137px;
      height: 120px;

      padding: 0px 31.5px;
      object-fit: contain;
    }
    .ImagePartner38 {
      width: 128px;
      height: 52.08px;

      padding: 33.96px 26px;
      object-fit: contain;
    }
    .ImagePartner39 {
      width: 120px;
      height: 48.13px;

      padding: 35.94px 30px;
      object-fit: contain;
    }
    .ImagePartner40 {
      width: 136px;
      height: 76px;

      padding: 22px;
      object-fit: contain;
    }
    .ImagePartner41 {
      width: 136px;
      height: 101.23px;

      padding: 9.39px 32px;
      object-fit: contain;
    }
    .ImagePartner42 {
      width: 84px;
      height: 78px;

      padding: 21px 48px;
      object-fit: contain;
    }
    .ImagePartner43 {
      width: 54px;
      height: 65.3px;

      padding: 27.35px 63px;
      object-fit: contain;
    }
    .ImagePartner44 {
      width: 76px;
      height: 84.75px;

      padding: 17.63px 52px;
      object-fit: contain;
    }
    .ImagePartner45 {
      width: 112px;
      height: 83.36px;

      padding: 18.32px 34px;
      object-fit: contain;
    }
    .ImagePartner46 {
      width: 92px;
      height: 86px;

      padding: 17px 14px;
      object-fit: contain;
    }
    .ImagePartner47 {
      width: 112px;
      height: 112px;

      padding: 4px 34px;
      object-fit: contain;
    }
    .ImagePartner48 {
      width: 112px;
      height: 112px;

      padding: 33px 39px;
      object-fit: contain;
    }
    .ImagePartner49 {
      width: 114px;
      height: 54px;

      padding: 33px;
      object-fit: contain;
    }
    .ImagePartner50 {
      width: 164px;
      height: 20px;

      padding: 50px 8px;

      object-fit: contain;
    }
    .ImagePartner51 {
      width: 72px;
      height: 64px;

      padding: 28px 54px;
      object-fit: contain;
    }
    .ImagePartner52 {
      width: 142px;
      height: 54px;

      padding: 33px 19px;
      object-fit: contain;
    }
    .ImagePartner53 {
      width: 88px;
      height: 44px;

      padding: 38px 46px;
      object-fit: contain;
    }
    .ImagePartner70 {
      width: 77px;
      height: 62px;

      padding: 29px 51.5px;
      object-fit: contain;
    }
    .ImagePartner71 {
      width: 73px;
      height: 66px;

      padding: 27px 53.5px;
      object-fit: contain;
    }
    .ImagePartner64 {
      width: 136px;
      height: 30px;
      padding: 45px 22px;
    }
    .ImagePartner65 {
      width: 111px;
      height: 75.39px;

      padding: 22.3px 34.5px;
    }
    .ImagePartner66 {
      width: 86px;
      height: 86px;

      padding: 27px 47px;
    }
    .ImagePartner54 {
      width: 148px;
      height: 46px;

      padding: 32px 16px;
      object-fit: contain;
    }
    .ImagePartner55 {
      width: 136px;
      height: 47.59px;

      padding: 36.21px 22px;
      object-fit: contain;
    }
    .ImagePartner56 {
      width: 98px;
      height: 46px;

      padding: 37px 41px;
      object-fit: contain;
    }
    .ImagePartner57 {
      width: 126px;
      height: 72px;

      padding: 24px 27px;
      object-fit: contain;
    }
    .ImagePartner58 {
      width: 102px;
      height: 28px;

      padding: 46px 39px;
      object-fit: contain;
    }
    .ImagePartner59 {
      width: 80px;
      height: 31.67px;

      padding: 44.17px 50px;
      object-fit: contain;
    }
    .ImagePartner60 {
      width: 126px;
      height: 36px;

      padding: 52px 27px;
      object-fit: contain;
    }
    .ImagePartner61 {
      width: 116px;
      height: 42px;

      padding: 39px 32px;
      object-fit: contain;
    }
    .ImagePartner62 {
      width: 116px;
      height: 32.66px;

      padding: 42.67px 32px;
      object-fit: contain;
    }
    .ImagePartner63 {
      width: 160px;
      height: 22px;

      padding: 49px 10px;
      object-fit: contain;
    }
    .ImagePartner67 {
      width: 79px;
      height: 79px;

      padding: 20.5px 50.5px;
      object-fit: contain;
    }
    .ImagePartner68 {
      width: 85px;
      height: 76px;

      padding: 22px 47.5px;
      object-fit: contain;
    }
    .ImagePartner70 {
      width: 77px;
      height: 62px;

      padding: 29px 51.5px;
      object-fit: contain;
    }
    .ImagePartner71 {
      width: 73px;
      height: 66px;

      padding: 27px 53.5px;
      object-fit: contain;
    }
    .ImagePartner69 {
      width: 72px;
      height: 74.33px;

      padding: 22.51px 51.5px;
      object-fit: contain;
    }
    .ImagePartner72 {
      width: 110px;
      height: 38px;
      padding: 41px 35px;
      object-fit: contain;
    }
    .ImagePartner73 {
      width: 146px;
      height: 26px;

      padding: 47px 17px;
      object-fit: contain;
    }
  }
}
.ExhibitorWrapperDesktop {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px 50px;
  margin-top: 32px;
  margin-bottom: 72px;

  @media (max-width: 767.98px) {
    display: none;
  }
}
.ImageExhibitor {
  width: 139px;
  height: 80px;
  object-fit: contain;
  margin-top: 23px;
}
.Private {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px 50px;
  margin-bottom: 72px;
  margin-top: 18px;
  @media (max-width: 767.98px) {
    flex-direction: column;
    gap: 32px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &Item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .privateImage2 {
    width: 90px;
    height: 59px;
  }
}
.ImageEx1 {
  width: 116px;
  height: 32px;
  padding: 44px 32px;
}
.ImageEx2 {
  width: 170px;
  height: 36px;
  padding: 42px 5px;
}
.ImageEx3 {
  width: 102px;
  height: 102px;
  padding: 9px 39px;
}
.ImageEx4 {
  width: 136px;
  height: 28px;
  padding: 46px 22px;
}
.ImageEx5 {
  width: 138px;
  height: 30px;

  padding: 45px 21px;
}
.ImageEx6 {
  width: 144px;
  height: 44px;

  padding: 36px 18px;
}
.ImageEx7 {
  width: 134px;
  height: 20px;

  padding: 50px 23px;
}
.ImageEx8 {
  width: 124px;
  height: 28px;

  padding: 40px 28px;
}
.ImagePri1 {
  width: 93px;
  height: 33px;
}
.ImagePri2 {
  width: 123px;
  height: 28px;
}
.ImagePri3 {
  width: 77px;
  height: 62px;
}
.ImagePri4 {
  width: 129px;
  height: 20px;
}
.ImagePri5 {
  width: 77px;
  height: 58px;
}
.grid-container-ex {
  display: flex;
  flex-direction: column;
  gap: 42px;

  .grid-row {
    display: flex;
    gap: 42px;
    justify-content: center;
  }
  @media (max-width: 767.98px) {
    display: none;
  }
}
.grid-container {
  display: flex;
  gap: 22px;
  max-width: 1230px;
  flex-wrap: wrap;
  justify-content: center;

  .Image {
    padding: 16px;
    height: 63px;
  }

  @media (max-width: 767.98px) {
    display: none;
  }
}
.grid-container-co {
  display: flex;
  gap: 39px;
  width: 1030px;
  flex-wrap: wrap;
  justify-content: center;
  height: 680px;
  .Image {
    padding: 16px;
    height: 63px;
  }

  @media (max-width: 767.98px) {
    display: none;
  }
}
.grid-container-co-2 {
  display: flex;
  gap: 39px;
  width: 1100px;
  flex-wrap: wrap;
  padding-left: 80px;
  justify-content: center;
  height: 580px;

  .Image {
    padding: 16px;
    height: 63px;
  }

  @media (max-width: 767.98px) {
    display: none;
  }
}
.grid-container-co-1 {
  display: flex;
  gap: 39px;
  width: 1055px;
  flex-wrap: wrap;
  justify-content: center;
  height: 580px;

  .Image {
    padding: 16px;
    height: 63px;
  }

  @media (max-width: 767.98px) {
    display: none;
  }
}
.PartnerWrapperSlider {
  display: none;
  width: 300px;

  @media (max-width: 767.98px) {
    display: flex;
  }

  .SwiperPartner {
    width: 100%;
    overflow: unset;
  }

  &Item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .Image {
      height: 43px;
      padding: 0px;
    }
  }
}

.MB-0 {
  margin-bottom: 0 !important;
}

.Fz-28 {
  font-size: 28px !important;
  letter-spacing: 10% !important;

  @media (max-width: 767.98px) {
    width: 323px;
    font-size: 22px !important;
    line-height: normal;
    letter-spacing: 2.2px !important;
  }
}

.Fz-25 {
  font-size: 23px !important;
  line-height: 29.32px;
  letter-spacing: 10% !important;

  @media (max-width: 767.98px) {
    width: 323px;
    font-size: 20px !important;
    line-height: normal;
    letter-spacing: 2.2px !important;
  }
}

.Swiper {
  overflow: unset !important;
}

:global {
  .swiper-wrapper {
    align-items: center;
  }

  .swiper-slide {
    width: fit-content !important;
  }
}

.ImageGold {
  height: 72px;
  width: auto;
}

.ImageVenue {
  height: 60px;
  width: auto;
}

.Height-62 {
  height: 62px !important;
}
.ex3 {
  width: 138.58px !important;
  height: 27.39px !important;
}
.ex6 {
  width: 103.17px !important;
  height: 24.14px !important;
}
.ex9 {
  width: 124.09px !important;
  height: 25.52px !important;
}

.Btn {
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-18px);
  width: 189px;
  height: 43.23px;
  background-image: url('../../../assets/images/background-btn-header.png');
  font-family: 'Poppins';
  background-repeat: no-repeat;
  font-style: italic;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;

  color: #ffffff;

  position: relative;
  @media (max-width: 767.98px) {
    transform: translateX(0px);
  }
  &::before {
    content: ' ';
    width: 140px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    background: linear-gradient(
      93.01deg,
      #4895ff 0%,
      #00ffce 31.77%,
      #847be7 67.19%,
      #d629f7 89.06%,
      #ff00ff 100%
    );
    opacity: 0.4;
    filter: blur(15px);
    transform: skewX(-20deg) translateX(-50%);
    z-index: -1;
  }
}

.Community {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (max-width: 767.98px) {
    gap: 30px;
    padding: 0px;
  }
  .Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    justify-content: center;
    width: 100%;
  }

  .WrapperDesktop {
    width: 1140px;
    margin: 0 auto;
    position: relative;
    display: block;
    @media (max-width: 767.98px) {
      display: none;
    }
    .Back {
      position: absolute;
      z-index: 2;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }

    .Next {
      position: absolute;
      left: 0px;
      z-index: 2;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
    .Wrapper {
      width: 100vw !important;
      display: flex;
      justify-content: center;
      height: 500px;
    }
    .grid-container {
      display: flex;
      gap: 32px;
      flex-wrap: wrap;
      justify-content: center;
      width: 1230px;
    }
    .Swiper {
      overflow: hidden !important;
      // margin-top: 205px;
    }
  }
  .WrapperPartnerLogo {
    display: flex;
    z-index: 4;
    width: 1000px;
    gap: 39px;
    justify-content: center;
    align-items: center;
    @media (max-width: 767.98px) {
      display: none;
    }
    .PartnerLogo {
      display: flex;
      gap: 39px;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
