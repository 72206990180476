.Component {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
  width: 100%;
  height: 1200px;
  position: relative;
  gap: 32px;
  background-image: url(../../assets/images/bgG.png);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 100% 20%;
  background-color: #00110e;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../assets/images/bgG.png);
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    background-size: 100% 20%;
    filter: blur(8px);
    z-index: 1;
    @media (max-width: 767.98px) {
      background-size: 100% 20%;
    }
  }
  @media (max-width: 767.98px) {
    padding-top: 80px;

    height: 1500px;
    background-size: 100% 10%;

    background-size: 100% 10%;
  }
  .BG1 {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 270px;
    filter: blur(5px);

    @media (max-width: 767.98px) {
      scale: 0.5;
      left: -28%;
      top: -60px;
      filter: blur(3px);
    }
  }
  .BG2 {
    z-index: 1;
    position: absolute;
    right: 0;
    top: 50px;
    filter: blur(5px);

    @media (max-width: 767.98px) {
      scale: 0.5;
      right: -28%;
      top: 350px;
      filter: blur(3px);
    }
  }

  .Content {
    background-image: url(../../assets/images/banner/vctor.png);
    object-fit: contain;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    padding: 40px 0px 40px 0px;

    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 767.98px) {
      background-size: 100% 60px;
      background-image: url(../../assets/images/vetor14.png);
    }
    .Text {
      width: fit-content;
      font-family: 'Audiowide';
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 40.08px;
      text-transform: uppercase;
      text-align: center;

      background: linear-gradient(
        93.01deg,
        #4895ff 0%,
        #00ffce 31.77%,
        #847be7 67.19%,
        #d629f7 89.06%,
        #ff00ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;

      @media (max-width: 767.98px) {
        font-size: 20px;
        line-height: 25.5px;
      }
    }
  }

  .TabBar {
    z-index: 2;
    display: flex;
    gap: 32px;
    align-items: center;
    justify-content: center;
    @media (max-width: 767.98px) {
      gap: 13.41px;
    }
    .Box {
      width: 226px;
      height: 43px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
      cursor: pointer;
      @media (max-width: 767.98px) {
        height: 11.16px;
        width: fit-content;
        padding: 8px;
        gap: 3.33px;
      }
      .Text {
        font-family: Audiowide;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.2px;
        text-align: center;
        color: white;
        @media (max-width: 767.98px) {
          font-family: Audiowide;
          font-size: 6.38px;
          font-weight: 400;
          line-height: 7.66px;
          text-align: center;
        }
      }
      .Line {
        height: 1px;
        width: 102px;
        background: white;
        @media (max-width: 767.98px) {
          width: 42.76px;
        }
      }
      .active {
        color: #00ffce;
      }
      .Line.active {
        background: #00ffce;
      }
    }
  }
  .Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 32px;
    width: 100%;
  }
  .Contain {
    z-index: 5;
    // display: grid;
    // grid-template-columns: repeat(3, 1fr);
    // grid-template-rows: repeat(3, 1fr);
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    width: 1236px;
    height: 200px;
    align-items: center;
    justify-content: center;
    @media (max-width: 767.98px) {
      width: 400px;
      height: 780px;
      // grid-template-columns: repeat(2, 1fr);
      display: flex;
      flex-wrap: wrap;
    }
    // .Box {
    //   width: 1236px;
    //   height: 872px;
    //   padding: 40px 50px 40px 50px;
    //   gap: 72px;
    //   border-radius: 14px;
    //   position: relative;
    //   box-shadow: 0px 4px 4px 0px #00000040;

    //   box-shadow: 5px 5px 6px 0px #88fff31a inset;

    //   box-shadow: 0px -2px 3px 0px #5f8dfc2b inset;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   background: linear-gradient(
    //       105.26deg,
    //       rgba(0, 182, 222, 0.2) -1.45%,
    //       rgba(0, 92, 113, 0.2) 24.31%,
    //       rgba(0, 0, 0, 0.2) 88.46%
    //     ),
    //     linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    //   .image {
    //     position: absolute;
    //     top: 0;
    //     @media (max-width: 767.98px) {
    //       width: 225.08px;
    //       height: 87.13px;
    //     }
    //   }
    //   @media (max-width: 767.98px) {
    //     width: 90%;
    //     height: 800px;
    //   }
    // }
    // .Update {
    //   background: #00110ecc;
    //   &:before {
    //     content: 'Update Soon';
    //     color: #00ffce;
    //     background: #00110ecc;
    //     font-family: Audiowide;
    //     font-size: 42px;
    //     font-weight: 400;
    //     line-height: 50.4px;
    //     text-align: center;
    //     @media (max-width: 767.98px) {
    //       font-family: Audiowide;
    //       font-size: 24px;
    //       font-weight: 400;
    //       line-height: 28.8px;
    //       text-align: center;
    //     }
    //   }
    // }
    // .Btn {
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   width: 189px;
    //   height: 43.23px;
    //   background-image: url('../../assets/images/info/Union\ \(1\).png');

    //   font-family: 'Aileron';
    //   font-style: italic;
    //   font-weight: 700;
    //   font-size: 18.67px;
    //   line-height: 22.4px;

    //   color: #ffffff;
    //   position: relative;
    //   margin-bottom: 40px;
    //   @media (max-width: 767.98px) {
    //     margin-bottom: 0px;
    //     display: none;
    //   }

    //   &::before {
    //     content: ' ';
    //     width: 177px;
    //     height: 100%;
    //     position: absolute;
    //     top: 0;
    //     left: 50%;
    //     background: linear-gradient(
    //       93.01deg,
    //       #4895ff 0%,
    //       #00ffce 31.77%,
    //       #847be7 67.19%,
    //       #d629f7 89.06%,
    //       #ff00ff 100%
    //     );
    //     filter: blur(15px);
    //     transform: skewX(-20deg) translateX(-50%);
    //     z-index: -1;
    //   }
    // }

    // .Slider {
    //   display: flex;
    //   flex-direction: row;
    //   gap: 8px;
    //   .Circle {
    //     width: 9.69px;
    //     height: 9.69px;
    //     border-radius: 9999px;
    //     background: white;
    //   }
    //   .Active {
    //     background: #00ffce;
    //   }
    // }
    .ContainBox {
      z-index: 5;

      width: 335px;
      height: 222.39px;
      display: flex;
      gap: 32px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 9.22px;
      border: solid #044441 1px;
      background: linear-gradient(
        119.25deg,
        rgba(40, 45, 44, 0.15) 32.05%,
        rgba(0, 17, 14, 0.105) 102%
      );
      box-shadow: 0px 4.96px 14.18px 0px #00000033;

      box-shadow: 2.13px 2.13px 8.86px 0px #88fff312 inset;

      box-shadow: 1.42px 1.42px 0.71px 0px #ffffff52 inset;

      box-shadow: -1.42px -1.42px 4.25px 0px #ff00ff14 inset;
      padding: 32px;
      @media (max-width: 767.98px) {
        width: 120.5px;
        height: 218.5px;
        padding: 15.75px;
      }
      .Title {
        width: fit-content;
        font-family: Audiowide;
        font-size: 20px;
        font-weight: 400;
        line-height: 25.5px;
        text-align: center;

        text-transform: capitalize;

        background: linear-gradient(
          93.01deg,
          #4895ff 0%,
          #00ffce 31.77%,
          #847be7 67.19%,
          #d629f7 89.06%,
          #ff00ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        @media (max-width: 767.98px) {
          font-family: Audiowide;
          font-size: 16px;
          font-weight: 400;
          line-height: 20.4px;
          text-align: center;
        }
      }

      .Logo {
        display: flex;
        gap: 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .vngcloud1 {
          width: 190px;
          height: 75px;
          object-fit: contain;
          @media (max-width: 767.98px) {
            width: 126px;
            height: 50px;
          }
        }
        .image4 {
          @media (max-width: 767.98px) {
            width: 102px;
            height: 23px;
            padding: 18.5px 6.75px 8.5px 6.75px;
          }
        }
        .Name {
          font-family: Audiowide;
          font-size: 15px;
          font-weight: 400;
          line-height: 19.12px;
          letter-spacing: 0.085em;
          text-align: center;

          color: #00ffce;
          text-transform: uppercase;
          @media (max-width: 767.98px) {
            font-family: Audiowide;
            font-size: 12px;
            font-weight: 400;
            line-height: 15.3px;
            letter-spacing: 0.085em;
            text-align: center;
          }
        }
      }
      .btn {
        width: 94.27px;
        height: 23.39px;
        padding: 1.77px 10.63px 1.77px 10.63px;
        border-radius: 4.25px;
        background: #00110e;
        box-shadow: 0px 0.71px 2.13px 0px #00000033;

        box-shadow: 0px 0.71px 1.06px 0px #00ffce0f inset;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Audiowide;
        font-size: 11px;
        font-weight: 400;
        line-height: 14.02px;
        letter-spacing: 0.24em;
        text-align: center;
        .Item {
          color: #00ffce;
          text-transform: uppercase;
        }
      }
    }
  }
  .Pagination {
    z-index: 5;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
  .Pagination button {
    z-index: 5;

    cursor: pointer;
    font-family: Audiowide;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;
    width: 35px;
    height: 35px;
    padding: 8px 0px;

    gap: 10px;
    border-radius: 4px;
    background: linear-gradient(
        105.26deg,
        rgba(0, 182, 222, 0.2) -1.45%,
        rgba(0, 92, 113, 0.2) 24.31%,
        rgba(0, 0, 0, 0.2) 88.46%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  }

  .Pagination button.active {
    font-family: Audiowide;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;

    z-index: 5;

    width: 35px;
    height: 35px;
    padding: 8px 0px;
    gap: 10px;
    border-radius: 4px;

    color: #00ffce;
    box-shadow: 1px 1px 2px 0px #3ec1b11a;

    box-shadow: 2px 4px 4px 0px #3ec1b117;

    box-shadow: 5px 8px 6px 0px #3ec1b10d;

    box-shadow: 9px 14px 7px 0px #3ec1b103;

    box-shadow: 14px 22px 7px 0px #3ec1b100;
    background: linear-gradient(
        105.26deg,
        rgba(0, 182, 222, 0.2) -1.45%,
        rgba(0, 92, 113, 0.2) 24.31%,
        rgba(0, 0, 0, 0.2) 88.46%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  }
}
