.Component {
  display: flex;
  flex-direction: column;
  position: relative;

  padding: 122px 0 0px 0;
  background-color: #00110e;
  overflow: hidden;
  @media (max-width: 767.98px) {
    padding: 40px;
  }
  &::before {
    content: ' ';
    height: 354px;
    width: 100%;

    background: linear-gradient(
      180deg,
      #00110e 16.67%,
      rgba(0, 17, 14, 0) 100%
    );

    position: absolute;
    top: 0;
    left: 0;

    z-index: 3;
  }

  .Background {
    width: 100%;
    height: 903px;
    object-fit: cover;

    position: absolute;
    left: 50%;
    bottom: 0;

    transform: translateX(-50%);

    z-index: 1;

    @media (min-width: 1440px) {
      object-fit: unset;
    }
    @media (max-width: 767.98px) {
      filter: blur(2px);
    }
  }

  .BackgroundCircle {
    position: absolute;
    bottom: -71px;
    left: calc(50% + 434px);
    transform: translateX(-50%);

    opacity: 0.6;
  }

  .Content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    gap: 64px;

    z-index: 4;
    @media (max-width: 767.98px) {
      gap: 30px;
    }
    .Title {
      margin: 0 auto;
      height: 87px;
      width: 100%;
      font-family: 'Audiowide';
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 40.8px;

      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      background: linear-gradient(
        93.01deg,
        #4895ff 0%,
        #00ffce 31.77%,
        #847be7 67.19%,
        #d629f7 89.06%,
        #ff00ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 767.98px) {
        font-size: 20px;
        line-height: 25.5px;
        height: 50px;
      }
    }

    .Card {
      display: flex;
      width: 100%;
      max-width: 1046px;
      margin: 0 auto;
      position: relative;
      @media (max-width: 767.98px) {
        display: none;
      }
      .Back {
        position: absolute;
        z-index: 2;
        right: -70px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;

        @media (max-width: 1260px) {
          right: -44px;
        }
      }

      .Next {
        position: absolute;
        left: -70px;
        z-index: 2;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;

        @media (max-width: 1260px) {
          left: -44px;
        }
      }

      &Item {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 11.14px;
        align-items: center;
        padding: 31.96px 30.24px;
        width: 266.9px;
        height: 286.09px;

        background: linear-gradient(
          119.25deg,
          rgba(18, 39, 36, 0.8) 32.05%,
          rgba(0, 17, 14, 0.8) 102%
        );
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.35);
        backdrop-filter: blur(7px);

        border-radius: 8.91px;

        .Avatar {
          height: 148.92px;
        }

        .Info {
          display: flex;
          flex-direction: column;
          gap: 12px;

          .Name {
            font-family: 'Audiowide';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 25px;
            text-align: center;

            /* One/Gradient */

            background: linear-gradient(
              93.01deg,
              #4895ff 0%,
              #00ffce 31.77%,
              #847be7 67.19%,
              #d629f7 89.06%,
              #ff00ff 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }

          .Role {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 13px;
            line-height: 120%;
            /* identical to box height, or 18px */

            text-align: center;
            letter-spacing: 0.085em;
            text-transform: uppercase;
            opacity: 0.9;

            color: #ffffff;
          }

          .Desc {
            color: #fff;
            text-align: center;
            font-size: 13px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            letter-spacing: 0.91px;
            text-transform: uppercase;
            margin-bottom: -5px;

            opacity: 0.9;
          }

          .Strong {
            & span {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 700;
              font-size: 13px;
              line-height: 120%;
              /* identical to box height, or 18px */

              text-align: center;
              letter-spacing: 0.085em;
              text-transform: uppercase;
              opacity: 0.9;

              color: #ffffff;
            }
          }

          .Thin {
            color: #fff;
            text-align: center;
            font-size: 13px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            letter-spacing: 0.91px;
            text-transform: uppercase;
            margin-bottom: -5px;

            opacity: 0.9;
            & span {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 700;
              font-size: 13px;
              line-height: 120%;
              /* identical to box height, or 18px */

              text-align: center;
              letter-spacing: 0.085em;
              text-transform: uppercase;
              opacity: 0.9;

              color: #ffffff;
            }
          }
        }

        .Link {
          display: flex;
          justify-content: center;
          padding-top: 46.8px;
          & a {
            color: #00ffce;
            text-align: center;
            font-size: 10px;
            font-family: Audiowide;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 1.6px;
            text-transform: uppercase;
            padding: 5px 12px;
            border-radius: 4px;
            background: #00110e;
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2),
              0px 2px 3px 0px rgba(0, 255, 206, 0.06) inset;
          }
        }
      }
    }
    .WrapperMobile {
      display: none;
      width: 335px;
      z-index: 4;
      @media (max-width: 767.98px) {
        height: 412px;
        padding-bottom: 80px;
        display: block;
        margin: 0 auto;
      }
      .SwiperPartner {
        width: 100%;
        overflow: unset;
        .Contain {
          height: 430px;
        }
        .CardItem {
          width: 300px;
          height: 318px;
          padding: 30px 18px 30px 18px;
          gap: 20px;
          border-radius: 10px 0px 0px 0px;
          align-items: center;
          justify-content: center;
          display: flex;
          flex-direction: column;
          background: linear-gradient(
            119.25deg,
            rgba(18, 39, 36, 0.8) 32.05%,
            rgba(0, 17, 14, 0.8) 102%
          );
          box-shadow: 0px 4px 12px 0px #00000059;
          .Avatar {
            height: 143.31px;
          }

          .Info {
            display: flex;
            flex-direction: column;
            gap: 12px;

            .Name {
              font-family: 'Audiowide';
              font-style: normal;
              font-weight: 400;
              font-size: 20px;
              line-height: 25.5px;
              text-align: center;

              /* One/Gradient */

              background: linear-gradient(
                93.01deg,
                #4895ff 0%,
                #00ffce 31.77%,
                #847be7 67.19%,
                #d629f7 89.06%,
                #ff00ff 100%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }

            .Role {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 700;
              font-size: 13px;
              line-height: 120%;
              /* identical to box height, or 18px */

              text-align: center;
              letter-spacing: 0.085em;
              text-transform: uppercase;
              opacity: 0.9;

              color: #ffffff;
            }

            .Desc {
              color: #fff;
              text-align: center;
              font-size: 13px;
              font-family: Poppins;
              font-style: normal;
              font-weight: 400;
              line-height: 1;
              letter-spacing: 0.91px;
              text-transform: uppercase;
              margin-bottom: -5px;

              opacity: 0.9;
            }

            .Strong {
              & span {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 700;
                font-size: 13px;
                line-height: 120%;
                /* identical to box height, or 18px */

                text-align: center;
                letter-spacing: 0.085em;
                text-transform: uppercase;
                opacity: 0.9;

                color: #ffffff;
              }
            }

            .Thin {
              color: #fff;
              text-align: center;
              font-size: 13px;
              font-family: Poppins;
              font-style: normal;
              font-weight: 400;
              line-height: 1;
              letter-spacing: 0.91px;
              text-transform: uppercase;
              margin-bottom: -5px;

              opacity: 0.9;
              & span {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 700;
                font-size: 13px;
                line-height: 120%;
                /* identical to box height, or 18px */

                text-align: center;
                letter-spacing: 0.085em;
                text-transform: uppercase;
                opacity: 0.9;

                color: #ffffff;
              }
            }
          }

          .Link {
            display: flex;
            justify-content: center;

            & a {
              color: #00ffce;
              text-align: center;
              font-size: 10px;
              font-family: Audiowide;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: 1.6px;
              text-transform: uppercase;
              padding: 5px 12px;
              border-radius: 4px;
              background: #00110e;
              box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2),
                0px 2px 3px 0px rgba(0, 255, 206, 0.06) inset;
            }
          }
        }
      }
    }
    .Btn {
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 2;
      padding-bottom: 40px;
      @media (max-width: 767.98px) {
        display: none;
      }
      &Item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 217px;
        height: 52px;
        background-image: url(../../../assets/images/bg-btn-banner.png);

        font-family: 'Poppins';
        font-style: italic;
        font-weight: 600;
        font-size: 19px;
        line-height: 28px;

        @media (max-width: 990px) {
          font-size: 16px;
        }

        &::before {
          content: ' ';
          width: 217px;
          height: 100%;
          position: absolute;
          top: 0;
          left: 50%;

          transform: skewX(-20deg) translateX(-50%);
          z-index: -1;
        }
      }
    }
  }
}
