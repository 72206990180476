.Component {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 163.23px;
  width: 100%;
  height: 9715px;
  position: relative;
  gap: 32px;
  background-image: url(../../assets/images/bgG.png);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 100% 5%;
  background-color: #00110e;
  @media (max-width: 767.98px) {
    padding-top: 119px;
    height: 9460px;
    background-size: 100% 5%;
  }
  .BG1 {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 1280px;
    filter: blur(5px);

    @media (max-width: 767.98px) {
      scale: 0.5;
      left: -38%;
      top: 46px;
      filter: blur(3px);
    }
  }
  .BG2 {
    z-index: 1;
    position: absolute;
    right: 50px;
    top: 3400px;
    filter: blur(10px);

    @media (max-width: 767.98px) {
      scale: 0.5;
      right: -65%;
      top: 1520px;
      filter: blur(10px);
    }
  }
  .BG3 {
    z-index: 1;
    position: absolute;
    right: 0px;
    top: 6300px;

    @media (max-width: 767.98px) {
      scale: 0.5;
      right: -12%;
      top: 4080px;
    }
  }
  .BG4 {
    z-index: 1;
    position: absolute;
    left: 0px;
    top: 6750px;

    @media (max-width: 767.98px) {
      scale: 0.5;
      left: -28%;
      top: 5420px;
    }
  }
  .Content {
    .Text {
      width: fit-content;
      font-family: 'Audiowide';
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 40.08px;
      text-transform: uppercase;
      text-align: center;

      background: linear-gradient(
        93.01deg,
        #4895ff 0%,
        #00ffce 31.77%,
        #847be7 67.19%,
        #d629f7 89.06%,
        #ff00ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;

      @media (max-width: 767.98px) {
        font-size: 20px;
        line-height: 25.5px;
      }
    }
  }

  .Contain {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 1230px;
    height: fit-content;
    align-items: center;
    position: relative;
    z-index: 10;
    @media (max-width: 767.98px) {
      width: 100%;
      height: fit-content;
    }
    .Box {
      width: 1230px;
      height: fit-content;
      padding: 40px 0px 40px 0px;
      border: solid #044441;
      border-width: 2px 0 2px 2px;
      filter: blur(0.5px);
      border-radius: 14px;
      position: relative;
      background: linear-gradient(
          105.26deg,
          rgba(0, 182, 222, 0.2) -1.45%,
          rgba(0, 92, 113, 0.2) 24.31%,
          rgba(0, 0, 0, 0.2) 88.46%
        ),
        linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
      box-shadow: 0px 4px 4px 0px #00000040;

      box-shadow: 5px 5px 6px 0px #88fff31a inset;

      box-shadow: 0px -2px 3px 0px #5f8dfc2b inset;

      display: flex;
      flex-direction: column;
      .image {
        position: absolute;
        top: 0;
        @media (max-width: 767.98px) {
          width: 225.08px;
          height: 87.13px;
        }
      }
      @media (max-width: 767.98px) {
        width: 90%;
        height: fit-content;
        padding: 30px 0px 30px 0px;
        gap: 22px;
      }

      .Title {
        background-image: url(../../assets/images/event/Vector18.png);
        object-fit: contain;
        background-repeat: no-repeat;
        background-position: center center;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: auto;
        height: 80px;
        @media (max-width: 767.98px) {
          height: 60px;

          background-size: 100% 60px;
          background-image: url(../../assets/images/vetor14.png);
        }
        .Text {
          font-family: Audiowide;
          font-size: 24px;
          font-weight: 400;
          line-height: 28.8px;
          text-align: center;

          background: linear-gradient(93.01deg, #4895ff 0%, #00ffce 100%);

          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;

          @media (max-width: 767.98px) {
            font-family: Audiowide;
            font-size: 18px;
            font-weight: 400;
            line-height: 21.6px;
            text-align: center;
          }
        }
      }

      .BoxContent {
        display: flex;
        flex-direction: column;
        gap: 42px;
        padding: 0px 50px 0px 50px;
        margin-bottom: 42px;
        @media (max-width: 767.98px) {
          gap: 22px;
        }
        .Line {
          width: 100%;
          height: 1px;
          background: #00ffce;
        }
        .BoxTime {
          display: flex;
          flex-direction: column;
          gap: 8px;
          @media (max-width: 767.98px) {
            gap: 6px;
          }
        }
        .ContentBox1 {
          display: flex;
          flex-direction: column;
          gap: 6px;
        }
        .ContentBox {
          display: flex;
          flex-direction: column;
          gap: 12px;
        }
        .Time {
          width: fit-content;
          text-transform: uppercase;
          font-family: Audiowide;
          font-size: 22px;
          font-weight: 400;
          line-height: 26.4px;
          text-align: left;
          background: linear-gradient(
            93.01deg,
            #4895ff 0%,
            #00ffce 31.77%,
            #847be7 67.19%,
            #d629f7 89.06%,
            #ff00ff 100%
          );

          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          @media (max-width: 767.98px) {
            font-family: Audiowide;
            font-size: 13px;
            font-weight: 400;
            line-height: 15.6px;
            text-align: left;
          }
        }
        .Desc {
          font-family: Poppins;
          font-size: 22px;
          font-weight: 400;
          line-height: 30.8px;
          letter-spacing: 0.085em;
          text-align: left;
          span {
            font-family: Poppins;
            font-size: 22px;
            font-weight: 700;
            line-height: 30.8px;
            letter-spacing: 0.085em;
            text-align: left;
            @media (max-width: 767.98px) {
              font-family: Poppins;
              font-size: 16px;
              font-weight: 700;
              line-height: 22.4px;
              letter-spacing: 0.085em;
              text-align: left;
            }
          }
          @media (max-width: 767.98px) {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            line-height: 22.4px;
            letter-spacing: 0.085em;
            text-align: left;
          }
        }
        .Note {
          font-family: Poppins;
          font-size: 22px;
          font-weight: 400;
          line-height: 30.8px;
          letter-spacing: 0.085em;
          text-align: left;
          opacity: 90%;
          color: #00ffce;
          @media (max-width: 767.98px) {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            line-height: 22.4px;
            letter-spacing: 0.085em;
            text-align: left;
          }
        }
        .text {
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0.085em;
          text-align: left;
          @media (max-width: 767.98px) {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: 0.085em;
            text-align: left;
          }
          span {
            font-weight: 700;
          }
        }
        .BoxSpeak {
          display: flex;
          flex-direction: column;
          gap: 4px;
          @media (max-width: 767.98px) {
            gap: 6px;
          }
        }
        .Note2 {
          font-family: Poppins;
          font-size: 22px;
          font-weight: 700;
          line-height: 33px;
          letter-spacing: 0.085em;
          text-align: left;
          @media (max-width: 767.98px) {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 700;
            line-height: 22.4px;
            letter-spacing: 0.085em;
            text-align: left;
          }
        }
      }

      .Launch {
        height: 873px;
        padding-bottom: 42px;
        .UpdateSoon {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            font-family: Audiowide;
            font-size: 42px;
            font-weight: 400;
            line-height: 50.4px;
            text-align: center;
            color: #00ffce;
            @media (max-width: 767.98px) {
              font-family: Audiowide;
              font-size: 24px;
              font-weight: 400;
              line-height: 28.8px;
              text-align: center;
            }
          }
        }
        @media (max-width: 767.98px) {
          height: 760px;
          padding-bottom: 0px;
        }
      }

      .Talent {
        height: 873px;
        .UpdateSoon {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            font-family: Audiowide;
            font-size: 42px;
            font-weight: 400;
            line-height: 50.4px;
            text-align: center;
            color: #00ffce;
            @media (max-width: 767.98px) {
              font-family: Audiowide;
              font-size: 24px;
              font-weight: 400;
              line-height: 28.8px;
              text-align: center;
            }
          }
        }
        @media (max-width: 767.98px) {
          height: 760px;
          padding-bottom: 0px;
        }
      }

      .Cap {
        height: 873px;
        .UpdateSoon {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            font-family: Audiowide;
            font-size: 42px;
            font-weight: 400;
            line-height: 50.4px;
            text-align: center;
            color: #00ffce;
            @media (max-width: 767.98px) {
              font-family: Audiowide;
              font-size: 24px;
              font-weight: 400;
              line-height: 28.8px;
              text-align: center;
            }
          }
        }
        @media (max-width: 767.98px) {
          height: 760px;
          padding-bottom: 0px;
        }
      }
    }
    .Update {
      background: #00110ecc;
      &:before {
        content: 'Update Soon';
        color: #00ffce;
        background: #00110ecc;
        font-family: Audiowide;
        font-size: 42px;
        font-weight: 400;
        line-height: 50.4px;
        text-align: center;
        @media (max-width: 767.98px) {
          font-family: Audiowide;
          font-size: 24px;
          font-weight: 400;
          line-height: 28.8px;
          text-align: center;
        }
      }
    }
    .Btn {
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateX(-18px);
      width: 189px;
      height: 43.23px;
      background-image: url('../../assets/images/background-btn-header.png');
      font-family: 'Poppins';
      font-style: italic;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;

      color: #ffffff;

      position: relative;

      &::before {
        content: ' ';
        width: 177px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        background: linear-gradient(
          93.01deg,
          #4895ff 0%,
          #00ffce 31.77%,
          #847be7 67.19%,
          #d629f7 89.06%,
          #ff00ff 100%
        );
        opacity: 0.4;
        filter: blur(15px);
        transform: skewX(-20deg) translateX(-50%);
        z-index: -1;
      }
    }
  }
}
