.Component {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
  width: 100%;
  height: 2450px;
  position: relative;
  background-image: url(../../assets/images/bgG.png);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 100% 20%;
  background-color: #00110e;
  @media (max-width: 767.98px) {
    height: 3050px;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../assets/images/bgG.png);
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    background-size: 100% 20%;
    filter: blur(8px);
    z-index: 1;
    @media (max-width: 767.98px) {
      background-size: 100% 10%;
    }
  }
  @media (max-width: 767.98px) {
    padding-top: 80px;

    height: 3050px;
    background-size: 100% 10%;

    background-size: 100% 10%;
  }
  .BG1 {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 270px;
    filter: blur(5px);

    @media (max-width: 767.98px) {
      scale: 0.5;
      left: -28%;
      top: -60px;
      filter: blur(3px);
    }
  }
  .BG2 {
    z-index: 1;
    position: absolute;
    right: 0;
    top: 450px;
    filter: blur(5px);

    @media (max-width: 767.98px) {
      scale: 0.5;
      right: -28%;
      top: 350px;
      filter: blur(3px);
    }
  }
  .Content {
    background-image: url(../../assets/images/banner/vctor.png);
    object-fit: contain;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: auto;
    padding: 40px 0px 40px 0px;

    @media (max-width: 767.98px) {
      background-size: 100% 60px;
      background-image: url(../../assets/images/vetor14.png);
    }
    .Text {
      width: fit-content;
      font-family: 'Audiowide';
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 40.08px;
      text-transform: uppercase;
      text-align: center;

      background: linear-gradient(
        93.01deg,
        #4895ff 0%,
        #00ffce 31.77%,
        #847be7 67.19%,
        #d629f7 89.06%,
        #ff00ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;

      @media (max-width: 767.98px) {
        font-size: 20px;
        line-height: 25.5px;
      }
    }
  }

  .TabBar {
    z-index: 2;
    display: flex;
    gap: 32px;
    align-items: center;
    justify-content: center;
    @media (max-width: 767.98px) {
      gap: 13.41px;
    }
    .Box {
      width: 226px;
      height: 43px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
      cursor: pointer;
      @media (max-width: 767.98px) {
        height: 11.16px;
        width: fit-content;
        padding: 8px;
        gap: 3.33px;
      }
      .Text {
        font-family: Audiowide;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.2px;
        text-align: center;
        color: white;
        @media (max-width: 767.98px) {
          font-family: Audiowide;
          font-size: 6.38px;
          font-weight: 400;
          line-height: 7.66px;
          text-align: center;
        }
      }
      .Line {
        height: 1px;
        width: 102px;
        background: white;
        @media (max-width: 767.98px) {
          width: 42.76px;
        }
      }
      .active {
        color: #00ffce;
      }
      .Line.active {
        background: #00ffce;
      }
    }
  }

  .Contain {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    width: 1536px;
    height: 100%;
    align-items: center;
    gap: 20px;
    @media (max-width: 767.98px) {
      width: 100%;
      height: 800px;
      gap: 30px;
    }
    // .Box {
    //   width: 1236px;
    //   height: 872px;
    //   padding: 40px 50px 40px 50px;
    //   gap: 72px;
    //   border-radius: 14px;
    //   position: relative;
    //   box-shadow: 0px 4px 4px 0px #00000040;

    //   box-shadow: 5px 5px 6px 0px #88fff31a inset;

    //   box-shadow: 0px -2px 3px 0px #5f8dfc2b inset;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   background: linear-gradient(
    //       105.26deg,
    //       rgba(0, 182, 222, 0.2) -1.45%,
    //       rgba(0, 92, 113, 0.2) 24.31%,
    //       rgba(0, 0, 0, 0.2) 88.46%
    //     ),
    //     linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    //   .image {
    //     position: absolute;
    //     top: 0;
    //     @media (max-width: 767.98px) {
    //       width: 225.08px;
    //       height: 87.13px;
    //     }
    //   }
    //   @media (max-width: 767.98px) {
    //     width: 90%;
    //     height: 800px;
    //   }
    // }
    // .Update {
    //   background: #00110ecc;
    //   &:before {
    //     content: 'Update Soon';
    //     color: #00ffce;
    //     background: #00110ecc;
    //     font-family: Audiowide;
    //     font-size: 42px;
    //     font-weight: 400;
    //     line-height: 50.4px;
    //     text-align: center;
    //     @media (max-width: 767.98px) {
    //       font-family: Audiowide;
    //       font-size: 24px;
    //       font-weight: 400;
    //       line-height: 28.8px;
    //       text-align: center;
    //     }
    //   }
    // }
    // .Btn {
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   width: 189px;
    //   height: 43.23px;
    //   background-image: url('../../assets/images/info/Union\ \(1\).png');

    //   font-family: 'Aileron';
    //   font-style: italic;
    //   font-weight: 700;
    //   font-size: 18.67px;
    //   line-height: 22.4px;

    //   color: #ffffff;
    //   position: relative;
    //   margin-bottom: 40px;
    //   @media (max-width: 767.98px) {
    //     margin-bottom: 0px;
    //     display: none;
    //   }

    //   &::before {
    //     content: ' ';
    //     width: 177px;
    //     height: 100%;
    //     position: absolute;
    //     top: 0;
    //     left: 50%;
    //     background: linear-gradient(
    //       93.01deg,
    //       #4895ff 0%,
    //       #00ffce 31.77%,
    //       #847be7 67.19%,
    //       #d629f7 89.06%,
    //       #ff00ff 100%
    //     );
    //     opacity: 0.4;
    //     filter: blur(15px);
    //     transform: skewX(-20deg) translateX(-50%);
    //     z-index: -1;
    //   }
    // }
    .Wrapper {
      z-index: 5;
    }
    .WrapperBox1 {
      z-index: 5;
      border: solid #044441 1px;

      width: 1166px;
      height: 351.69px;
      padding: 32px;
      gap: 32px;
      border-radius: 9.22px;
      background: linear-gradient(
        119.25deg,
        rgba(40, 45, 44, 0.35) 32.05%,
        rgba(0, 17, 14, 0.245) 102%
      );
      box-shadow: 0px 4.96px 14.18px 0px #00000033;

      box-shadow: 2.13px 2.13px 8.86px 0px #88fff312 inset;

      box-shadow: 1.42px 1.42px 0.71px 0px #ffffff52 inset;

      box-shadow: -1.42px -1.42px 4.25px 0px #ff00ff14 inset;

      @media (max-width: 767.98px) {
        width: 246px;
        height: 773.55px;
      }
      .Box {
        display: flex;
        gap: 32px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        @media (max-width: 767.98px) {
          flex-direction: column;
        }
        .Left {
          width: 567px;

          display: flex;
          gap: 32px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .Title {
            width: fit-content;
            font-family: 'Audiowide';
            font-style: normal;
            font-weight: 400;
            font-size: 42px;
            line-height: 53.55px;
            text-transform: capitalize;
            text-align: center;

            background: linear-gradient(
              93.01deg,
              #4895ff 0%,
              #00ffce 31.77%,
              #847be7 67.19%,
              #d629f7 89.06%,
              #ff00ff 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;

            @media (max-width: 767.98px) {
              font-size: 24px;
              line-height: 30.6px;
            }
          }

          .Logo {
            display: flex;
            gap: 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .Intel {
              width: 239.2px;
              height: 92.8px;

              padding: 13.6px 20.4px;
              @media (max-width: 767.98px) {
                width: 168px;
                height: 65.18px;

                padding: 0px;
              }
            }
            .Name {
              font-family: Audiowide;
              font-size: 24px;
              font-weight: 400;
              line-height: 30.6px;
              letter-spacing: 0.085em;
              text-align: center;
              color: #00ffce;
              text-transform: uppercase;
              @media (max-width: 767.98px) {
                font-family: Audiowide;
                font-size: 16px;
                font-weight: 400;
                line-height: 20.4px;
                letter-spacing: 0.085em;
                text-align: center;
              }
            }
          }
          .btn {
            width: 201.27px;
            height: 23.39px;
            padding: 1.77px 10.63px 1.77px 10.63px;
            border-radius: 4.25px;
            background: #00110e;
            box-shadow: 0px 0.71px 2.13px 0px #00000033;

            box-shadow: 0px 0.71px 1.06px 0px #00ffce0f inset;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: Audiowide;
            font-size: 11px;
            font-weight: 400;
            line-height: 14.02px;
            letter-spacing: 0.24em;
            text-align: center;
            .Item {
              color: #00ffce;
              text-transform: uppercase;
            }
            @media (max-width: 767.98px) {
              display: none;
            }
          }
        }
        .Right {
          width: 567px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 16px;
          flex-direction: column;
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          @media (max-width: 767.98px) {
            width: 246px;
            font-family: Poppins;
            font-size: 13px;
            font-weight: 400;
            line-height: 19.5px;
            text-align: center;
          }
        }
        .btnMobile {
          width: 201.27px;
          height: 23.39px;
          padding: 1.77px 10.63px 1.77px 10.63px;
          border-radius: 4.25px;
          background: #00110e;
          box-shadow: 0px 0.71px 2.13px 0px #00000033;

          box-shadow: 0px 0.71px 1.06px 0px #00ffce0f inset;
          display: none;
          justify-content: center;
          align-items: center;
          font-family: Audiowide;
          font-size: 11px;
          font-weight: 400;
          line-height: 14.02px;
          letter-spacing: 0.24em;
          text-align: center;
          .Item {
            color: #00ffce;
            text-transform: uppercase;
          }
          @media (max-width: 767.98px) {
            display: flex;
          }
        }
      }
    }

    .WrapperBox2 {
      z-index: 5;

      border: solid #044441 1px;

      width: 1166px;
      height: 464px;
      padding: 32px;
      gap: 32px;
      border-radius: 9.22px;
      background: linear-gradient(
        119.25deg,
        rgba(40, 45, 44, 0.35) 32.05%,
        rgba(0, 17, 14, 0.105) 102%
      );
      box-shadow: 0px 4.96px 14.18px 0px #00000033;

      box-shadow: 2.13px 2.13px 8.86px 0px #88fff312 inset;

      box-shadow: 1.42px 1.42px 0.71px 0px #00ffce52 inset;

      box-shadow: -1.42px -1.42px 4.25px 0px #ff00ff14 inset;
      @media (max-width: 767.98px) {
        width: 246px;
        height: 1090.44px;
      }
      .Box {
        display: flex;
        gap: 32px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        @media (max-width: 767.98px) {
          flex-direction: column;
        }
        .Left {
          width: 567px;

          display: flex;
          gap: 32px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .Title {
            width: fit-content;
            font-family: 'Audiowide';
            font-style: normal;
            font-weight: 400;
            font-size: 34px;
            line-height: 43.35px;
            text-transform: capitalize;

            text-align: center;

            background: linear-gradient(
              93.01deg,
              #4895ff 0%,
              #00ffce 31.77%,
              #847be7 67.19%,
              #d629f7 89.06%,
              #ff00ff 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;

            @media (max-width: 767.98px) {
              font-size: 20px;
              line-height: 25.5px;
            }
          }
          .LogoListDiamond {
            display: flex;
            flex-direction: row;
            gap: 40.8px;
            @media (max-width: 767.98px) {
              flex-direction: column;
              gap: 24px;
              justify-content: center;
              align-items: center;
            }
            .Line {
              width: 1px;
              height: 85.98px;
              opacity: 0.6px;
              background-color: #ffffff;
              display: block;
              @media (max-width: 767.98px) {
                display: none;
              }
            }
            .Cloud {
              width: 329.4px;
              height: 87.93px;
              @media (max-width: 767.98px) {
                width: 183.99px;
                height: 49.12px;
              }
            }
            .Ctrl {
              width: 143.93px;
              height: 82.74px;
              @media (max-width: 767.98px) {
                width: 106px;
                height: 60.93px;
              }
            }
          }
          .Logo {
            display: flex;
            gap: 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .Aws {
              width: 185px;
              height: 117px;

              padding: 18.75px 53.98px;
              @media (max-width: 767.98px) {
                width: 88.57px;
                height: 58.25px;
                padding: 10.8px 40.09px;
              }
            }
            .Name {
              font-family: Audiowide;
              font-size: 20px;
              font-weight: 400;
              line-height: 25.5px;
              letter-spacing: 0.085em;
              text-align: center;
              color: #00ffce;
              text-transform: uppercase;
              @media (max-width: 767.98px) {
                font-family: Audiowide;
                font-size: 16px;
                font-weight: 400;
                line-height: 20.4px;
                letter-spacing: 0.085em;
                text-align: center;
              }
            }
          }
          .btn {
            width: 201.27px;
            height: 23.39px;
            padding: 1.77px 10.63px 1.77px 10.63px;
            border-radius: 4.25px;
            background: #00110e;
            box-shadow: 0px 0.71px 2.13px 0px #00000033;

            box-shadow: 0px 0.71px 1.06px 0px #00ffce0f inset;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: Audiowide;
            font-size: 11px;
            font-weight: 400;
            line-height: 14.02px;
            letter-spacing: 0.24em;
            text-align: center;
            .Item {
              color: #00ffce;
              text-transform: uppercase;
            }
            @media (max-width: 767.98px) {
              display: none;
            }
          }
        }
        .Right {
          width: 567px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 16px;
          flex-direction: column;
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          @media (max-width: 767.98px) {
            width: 246px;
            font-family: Poppins;
            font-size: 13px;
            font-weight: 400;
            line-height: 19.5px;
            text-align: center;
          }
        }
        .btnMobile {
          width: 201.27px;
          height: 23.39px;
          padding: 1.77px 10.63px 1.77px 10.63px;
          border-radius: 4.25px;
          background: #00110e;
          box-shadow: 0px 0.71px 2.13px 0px #00000033;

          box-shadow: 0px 0.71px 1.06px 0px #00ffce0f inset;
          display: none;
          justify-content: center;
          align-items: center;
          font-family: Audiowide;
          font-size: 11px;
          font-weight: 400;
          line-height: 14.02px;
          letter-spacing: 0.24em;
          text-align: center;
          .Item {
            color: #00ffce;
          }
          @media (max-width: 767.98px) {
            display: flex;
          }
        }
      }
    }

    .WrapperBoxDesktop1 {
      z-index: 5;

      width: 1230px;

      height: 333.39px;
      gap: 20px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      @media (max-width: 767.98px) {
        width: 310px;
        height: 254.39px;
        margin-top: 30px;
      }
      .ContainBox {
        border: solid #044441 1px;

        width: 100%;
        display: flex;
        gap: 32px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 9.22px;
        background: linear-gradient(
          119.25deg,
          rgba(40, 45, 44, 0.35) 32.05%,
          rgba(0, 17, 14, 0.105) 102%
        );
        box-shadow: 0px 4.96px 14.18px 0px #00000033;

        box-shadow: 2.13px 2.13px 8.86px 0px #88fff312 inset;

        box-shadow: 1.42px 1.42px 0.71px 0px #ffffff52 inset;

        box-shadow: -1.42px -1.42px 4.25px 0px #ff00ff14 inset;
        padding: 32px;
        .Title {
          width: fit-content;
          font-family: 'Audiowide';
          font-style: normal;
          font-weight: 400;
          font-size: 26px;
          line-height: 33.15px;
          text-transform: capitalize;

          text-align: center;

          background: linear-gradient(
            93.01deg,
            #4895ff 0%,
            #00ffce 31.77%,
            #847be7 67.19%,
            #d629f7 89.06%,
            #ff00ff 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          @media (max-width: 767.98px) {
            font-family: Audiowide;
            font-size: 18px;
            font-weight: 400;
            line-height: 22.95px;
            text-align: center;
          }
        }

        .Logo {
          display: flex;
          gap: 16px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .vngcloud1 {
            width: 214.84px;
            height: 30px;
            padding: 40px 13.56px;
          }
          .dewards1 {
            width: 154.45px;
            height: 41.95px;
            padding: 34.03px 28.72px;
          }
          .pv {
            width: 248px;
            height: 46px;
            padding: 32px 13.56px;
            @media (max-width: 767.98px) {
              width: 150px;
              height: 28px;
            }
          }
          .Name {
            font-family: Audiowide;
            font-size: 18px;
            font-weight: 400;
            line-height: 22.95px;
            letter-spacing: 0.085em;
            text-align: center;

            color: #00ffce;
            text-transform: uppercase;
            @media (max-width: 767.98px) {
              font-family: Audiowide;
              font-size: 14px;
              font-weight: 400;
              line-height: 17.85px;
              letter-spacing: 0.085em;
              text-align: center;
            }
          }
        }
        .btn {
          width: 201.27px;
          height: 23.39px;
          padding: 1.77px 10.63px 1.77px 10.63px;
          border-radius: 4.25px;
          background: #00110e;
          box-shadow: 0px 0.71px 2.13px 0px #00000033;

          box-shadow: 0px 0.71px 1.06px 0px #00ffce0f inset;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: Audiowide;
          font-size: 11px;
          font-weight: 400;
          line-height: 14.02px;
          letter-spacing: 0.24em;
          text-align: center;
          .Item {
            color: #00ffce;
            text-transform: uppercase;
          }
        }
      }
    }
    // .WrapperMobile {
    //   width: 312px;
    //   margin: 0 auto;
    //   position: relative;
    //   display: none;
    //   @media (max-width: 767.98px) {
    //     display: block;
    //   }

    //   .Contain {
    //     height: 290px + 15.5px;
    //   }
    //   .Card {
    //     border: solid #044441 1px;

    //     display: flex;
    //     flex-direction: column;
    //     justify-content: flex-end;
    //     &Wrapper {
    //       align-items: center;

    //       display: flex;
    //       flex-direction: column;
    //       width: 246px;
    //       height: 202.39px;
    //       gap: 16px;
    //       background: linear-gradient(
    //         119.25deg,
    //         rgba(40, 45, 44, 0.35) 32.05%,
    //         rgba(0, 17, 14, 0.105) 102%
    //       );

    //       padding: 32px;
    //       border-radius: 9.22px;
    //       background: linear-gradient(
    //         119.25deg,
    //         rgba(40, 45, 44, 0.15) 32.05%,
    //         rgba(0, 17, 14, 0.105) 102%
    //       );
    //       box-shadow: 0px 4.96px 14.18px 0px #00000033;

    //       box-shadow: 2.13px 2.13px 8.86px 0px #88fff312 inset;

    //       box-shadow: 1.42px 1.42px 0.71px 0px #ffffff52 inset;

    //       box-shadow: -1.42px -1.42px 4.25px 0px #ff00ff14 inset;

    //       position: relative;

    //       .Title {
    //         width: fit-content;
    //         font-family: 'Audiowide';
    //         font-style: normal;
    //         font-weight: 400;
    //         font-size: 18px;
    //         line-height: 22.95px;
    //         text-transform: capitalize;

    //         text-align: center;

    //         background: linear-gradient(
    //           93.01deg,
    //           #4895ff 0%,
    //           #00ffce 31.77%,
    //           #847be7 67.19%,
    //           #d629f7 89.06%,
    //           #ff00ff 100%
    //         );
    //         -webkit-background-clip: text;
    //         -webkit-text-fill-color: transparent;
    //         background-clip: text;
    //         text-fill-color: transparent;
    //       }

    //       .Logo {
    //         display: flex;
    //         gap: 16px;
    //         flex-direction: column;
    //         justify-content: center;
    //         align-items: center;
    //         .vngcloud1 {
    //           width: 126.56px;
    //           height: 60px;
    //           object-fit: contain;
    //         }

    //         .Name {
    //           font-family: Audiowide;
    //           font-size: 14px;
    //           font-weight: 400;
    //           line-height: 17.85px;
    //           letter-spacing: 0.085em;
    //           text-align: center;

    //           color: #00ffce;
    //           text-transform: uppercase;
    //         }
    //       }
    //       .btn {
    //         width: 94.27px;
    //         height: 23.39px;
    //         padding: 1.77px 10.63px 1.77px 10.63px;
    //         border-radius: 4.25px;
    //         background: #00110e;
    //         box-shadow: 0px 0.71px 2.13px 0px #00000033;

    //         box-shadow: 0px 0.71px 1.06px 0px #00ffce0f inset;
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         font-family: Audiowide;
    //         font-size: 11px;
    //         font-weight: 400;
    //         line-height: 14.02px;
    //         letter-spacing: 0.24em;
    //         text-align: center;
    //         .Item {
    //           color: #00ffce;
    //           text-transform: uppercase;
    //         }
    //       }
    //     }
    //   }
    // }
    .WrapperBoxDesktop2 {
      z-index: 5;

      width: 1230px;
      height: 286.39px;
      gap: 20px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      @media (max-width: 767.98px) {
        display: none;
      }
      .ContainBox {
        width: 603px;
        height: 225.39px;
        border: solid #044441 1px;

        display: flex;
        gap: 32px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 9.22px;
        background: linear-gradient(
          119.25deg,
          rgba(40, 45, 44, 0.35) 32.05%,
          rgba(0, 17, 14, 0.105) 102%
        );
        box-shadow: 0px 4.96px 14.18px 0px #00000033;

        box-shadow: 2.13px 2.13px 8.86px 0px #88fff312 inset;

        box-shadow: 1.42px 1.42px 0.71px 0px #ffffff52 inset;

        box-shadow: -1.42px -1.42px 4.25px 0px #ff00ff14 inset;
        padding: 32px;
        .Title {
          width: fit-content;
          font-family: 'Audiowide';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 25.5px;
          text-transform: capitalize;

          text-align: center;

          background: linear-gradient(
            93.01deg,
            #4895ff 0%,
            #00ffce 31.77%,
            #847be7 67.19%,
            #d629f7 89.06%,
            #ff00ff 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }

        .Logo {
          display: flex;
          gap: 16px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 110px;
          .blance {
            width: 103px;
            height: 44px;

            padding: 15.5px 10.12px;
          }
          .pri {
            height: 75px;
          }
          .dewards2 {
            width: 128.5px;
            height: 34.9px;

            padding: 20.05px 14.85px;
          }
          .mlogo {
            width: 72.25px;
            height: 68.23px;
            padding: 3.38px 42.98px;
          }
          .Name {
            font-family: Audiowide;
            font-size: 15px;
            font-weight: 400;
            line-height: 19.12px;
            letter-spacing: 0.085em;
            text-align: center;

            color: #00ffce;
            text-transform: uppercase;
          }
        }
        .btn {
          width: 201.27px;
          height: 23.39px;
          padding: 1.77px 10.63px 1.77px 10.63px;
          border-radius: 4.25px;
          background: #00110e;
          box-shadow: 0px 0.71px 2.13px 0px #00000033;

          box-shadow: 0px 0.71px 1.06px 0px #00ffce0f inset;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: Audiowide;
          font-size: 11px;
          font-weight: 400;
          line-height: 14.02px;
          letter-spacing: 0.24em;
          text-align: center;
          .Item {
            color: #00ffce;
            text-transform: uppercase;
          }
          @media (max-width: 767.98px) {
            display: none;
          }
        }
      }
    }
    .WrapperMobileBronze {
      width: 311.4px;
      margin: 0 auto;
      position: relative;
      display: none;
      @media (max-width: 767.98px) {
        display: block;
      }

      .Contain {
        height: 280px + 15.5px;
      }
      .Card {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        border: solid #044441 1px;

        &Wrapper {
          align-items: center;

          display: flex;
          flex-direction: column;
          width: 246px;
          height: 202.39px;
          gap: 16px;
          background: linear-gradient(
            119.25deg,
            rgba(40, 45, 44, 0.35) 32.05%,
            rgba(0, 17, 14, 0.105) 102%
          );

          padding: 32px;
          border-radius: 9.22px;
          background: linear-gradient(
            119.25deg,
            rgba(40, 45, 44, 0.15) 32.05%,
            rgba(0, 17, 14, 0.105) 102%
          );
          box-shadow: 0px 4.96px 14.18px 0px #00000033;

          box-shadow: 2.13px 2.13px 8.86px 0px #88fff312 inset;

          box-shadow: 1.42px 1.42px 0.71px 0px #ffffff52 inset;

          box-shadow: -1.42px -1.42px 4.25px 0px #ff00ff14 inset;

          position: relative;

          .Title {
            width: fit-content;
            font-family: 'Audiowide';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20.4px;
            text-transform: uppercase;
            text-align: center;

            background: linear-gradient(
              93.01deg,
              #4895ff 0%,
              #00ffce 31.77%,
              #847be7 67.19%,
              #d629f7 89.06%,
              #ff00ff 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }

          .Logo {
            display: flex;
            gap: 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .vngcloud1 {
              width: 126.56px;
              height: 60px;
              object-fit: contain;
            }

            .Name {
              font-family: Audiowide;
              font-size: 13px;
              font-weight: 400;
              line-height: 16.57px;
              letter-spacing: 0.085em;
              text-align: center;

              color: #00ffce;
              text-transform: uppercase;
            }
          }
          .btn {
            width: 201.27px;
            height: 23.39px;
            padding: 1.77px 10.63px 1.77px 10.63px;
            border-radius: 4.25px;
            background: #00110e;
            box-shadow: 0px 0.71px 2.13px 0px #00000033;

            box-shadow: 0px 0.71px 1.06px 0px #00ffce0f inset;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: Audiowide;
            font-size: 11px;
            font-weight: 400;
            line-height: 14.02px;
            letter-spacing: 0.24em;
            text-align: center;
            .Item {
              color: #00ffce;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
}
