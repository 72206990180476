.Component {
  display: flex;
  flex-direction: column;
  gap: 52px;
  width: 100%;
  padding: 40px 0 60px 0;
  @media (max-width: 990px) {
    gap: 30px;
  }

  .Content {
    width: calc(100% - 60px);
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 990px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 50px;
    }

    .Left {
      display: flex;
      flex-direction: column;
      gap: 30px;
      max-width: 530px;

      @media (max-width: 990px) {
        align-items: center;
      }

      .Wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media (max-width: 990px) {
          align-items: center;
        }

        .Title {
          width: fit-content;
          font-family: 'Audiowide';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 25.5px;

          /* One/Gradient */

          background: linear-gradient(
            93.01deg,
            #4895ff 0%,
            #00ffce 31.77%,
            #847be7 67.19%,
            #d629f7 89.06%,
            #ff00ff 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }

        .Logo {
          width: 162px;
          height: 32px;
        }
      }

      .Text {
        display: flex;
        flex-direction: column;
        gap: 25px;
        color: #fff;
        font-size: 14px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        opacity: 0.8;
        @media (max-width: 990px) {
          gap: 8px;
        }
      }
    }

    .Right {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 420px;
      gap: 10px;

      @media (max-width: 900px) {
        padding-right: 0;
        min-width: 310px;
      }

      .Title {
        font-family: 'Audiowide';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 25.5px;

        background: linear-gradient(
          93.01deg,
          #4895ff 0%,
          #00ffce 31.77%,
          #847be7 67.19%,
          #d629f7 89.06%,
          #ff00ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }

      .Location {
        display: flex;
        flex-direction: column;
        gap: 14px;

        & span {
          display: block;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 800;
          font-size: 15px;
          line-height: 120%;
          /* identical to box height, or 18px */

          letter-spacing: 0.085em;
          text-transform: uppercase;

          color: #ffffff;

          opacity: 0.9;
        }

        .Info {
          display: flex;
          flex-direction: column;

          & a {
            color: #fff;
            font-size: 16px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 300;
            line-height: 150%;

            opacity: 0.8;
          }
        }
      }

      .Social {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 30px;

        @media (max-width: 990px) {
          align-items: center;
          margin-top: 50px;
        }

        &Wrapper {
          display: flex;
          gap: 14px;
        }

        & span {
          color: #fff;
          font-size: 14px;
          font-family: Poppins;
          font-style: normal;
          font-weight: 300;
          line-height: 150%;

          opacity: 0.8;
        }
      }
    }
  }

  .Web {
    display: flex;
    flex-direction: column;
    gap: 25px;

    .Line {
      height: 37px;
      width: 100%;
      object-fit: cover;

      @media (min-width: 1440px) {
        object-fit: unset;
      }

      @media (max-width: 900px) {
        object-fit: contain;
      }
    }

    .Host {
      font-family: 'Audiowide';
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 22px;

      text-align: center;
      letter-spacing: 0.24em;
      text-transform: uppercase;

      color: #00ffce;
      margin-bottom: 63px;
    }
  }

  .WrapperCountDow {
    display: flex;
    flex-direction: row;
    width: 100vw;
    justify-content: center;
    align-items: center;
    gap: 52px;
    @media (max-width: 990px) {
      bottom: -118px;
      gap: 30px;
    }

    .CountDown {
      display: flex;
      gap: 42px;
      justify-content: center;
      align-items: center;
      @media (max-width: 990px) {
        gap: 10px;
      }

      & div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 198.25px;
        height: 117px;

        border-radius: 14px;

        position: relative;
        z-index: 1;

        @media (max-width: 990px) {
          width: 68px;
          height: 66px;
        }

        & .Blur {
          width: 100%;
          height: 100%;
          padding: 1px;
          position: absolute;
          top: 0;
          left: 0;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
            0px 4px 20px rgba(255, 255, 255, 0.12),
            inset 0px -2px 3px rgba(95, 141, 252, 0.17),
            inset 5px 5px 6px rgba(136, 255, 243, 0.1);

          z-index: 1;

          .Bg {
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            padding: 1px;
            position: absolute;
            top: 2;
            left: 2;
            background-color: #00110e;

            z-index: 1;
          }

          .Filter {
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            padding: 1px;
            position: absolute;
            top: 2;
            left: 2;
            background: linear-gradient(
              109.2deg,
              rgba(0, 182, 222, 0.2) -1.74%,
              rgba(0, 92, 113, 0.2) 29.29%,
              rgba(0, 0, 0, 0.2) 106.6%
            );
            backdrop-filter: blur(7px);
            z-index: 2;
          }
        }

        & span {
          font-family: 'Audiowide';
          font-style: normal;
          font-weight: 400;
          font-size: 50px;
          line-height: 120%;

          text-align: center;

          color: #00ffce;

          position: relative;
          z-index: 2;

          @media (max-width: 990px) {
            font-size: 30px;
          }
        }

        & p {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;

          text-align: center;
          letter-spacing: 0.085em;
          text-transform: uppercase;

          color: #ffffff;

          opacity: 0.9;
          position: relative;
          z-index: 2;

          @media (max-width: 990px) {
            font-size: 9px;
          }
        }
      }

      .Day {
        .Blur {
          background: linear-gradient(93.01deg, #4895ff 0%, #00ffce 100%);
        }
      }

      .Hour {
        .Blur {
          background: linear-gradient(93.01deg, #00ffce 0%, #847be7 100%);
        }
      }

      .Minute {
        .Blur {
          background: linear-gradient(93.01deg, #847be7 57.29%, #d629f7 100%);
        }
      }

      .SECOND {
        .Blur {
          background: linear-gradient(93.01deg, #d629f7 0%, #ff00ff 100%);
        }
      }
    }

    .Btn {
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 2;
      width: 229px;
      height: 58px;
      padding: 16px 32px 16px 32px;
      align-items: center;

      @media (max-width: 990px) {
        display: none;
      }
      &Item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 229px;
        height: 58px;
        border-radius: 100px;
        font-family: 'Aileron';
        font-weight: 700;
        font-size: 22px;
        line-height: 26.4px;
        position: relative;
        background-clip: padding-box;

        background: linear-gradient(
          92.86deg,
          #4895ff -53.71%,
          #00ffce 4.48%,
          #847be7 69.35%,
          #d629f7 109.42%,
          #ff00ff 129.46%
        );

        text-transform: uppercase;
      }
    }
  }
}
